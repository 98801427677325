import { useNotificationStore } from '../../store/notificationStore'

interface Props {
  message: string
  onRemove: any
  type: 'success' | 'warning' | 'error' | 'infos'
}
const Notification = ({ message, onRemove, type }: Props): JSX.Element => {
  const typeMapping = {
    success: 'bg-green-500',
    warning: 'bg-orange-500',
    error: 'bg-red-500',
    infos: 'bg-sky-500'
  }

  return (
    <div
      className={`fixed top-4 right-4 w-64 ${typeMapping[type]} text-white p-3 rounded shadow-md`}
    >
      <div className="flex justify-between">
        <span className="text-xs">{message}</span>
        <button onClick={onRemove} className="text-white ml-2 hover:text-red-500">
          X
        </button>
      </div>
    </div>
  )
}

const Notifications = (): JSX.Element => {
  const { notifications, removeNotification } = useNotificationStore()

  return (
    <div className="fixed bg-firstColor top-4 right-4">
      {notifications.map((notification, index) => (
        <Notification
        type={notification.type}
        key={index}
        message={notification.message}
        onRemove={() => {
          removeNotification(notification.message)
        }}
        />
      ))}
    </div>
  )
}

export default Notifications
