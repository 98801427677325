import { useEffect, useState } from 'react'
import StudentsScreen from '../screens/students.screen'
import { affectStudentToClass, createNewStudent, downloadPartition, fetchClasses, fetchStudents } from '../services/adminService'
import { downloadFileBrowser } from '../services/browserService'
import { useNotificationStore } from '../store/notificationStore'

export default function Students (): JSX.Element {
  const [students, setStudents] = useState<any[] | string>('loading')
  const [classes, setClasses] = useState<any[]>([])
  const [newEltState, setNewEltState] = useState<null | 'pending' | 'success' | 'error'>(null)
  const { addNotification } = useNotificationStore()
  const [filters, setFilters] = useState({ name: '', classe: null })

  const setUpStudents = (): void => {
    fetchStudents().then(res => {
      if (res.error === null) {
        setStudents(res.data)
      }
    }).catch(() => {
      setStudents('error')
    })
  }

  const handleCreateNewStudent = (event: any): void => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)

    setNewEltState('pending')
    const classeId: number = data.classe_id !== 'none' ? data.classe_id as unknown as number : -1

    createNewStudent(data).then(async (res: any) => {
      if (res.error === null) {
        if (classeId !== -1) {
          await affectStudentToClass(Number(res.data[0].id), classeId)
        }
        setNewEltState('success')
        setStudents([...students, res.data[0]])
        event.target.reset()
      } else setNewEltState('error')
    }).catch(() => {
      setNewEltState('error')
    })
  }

  const handleDownloadPartitionFile = (filePath: string): void => {
    downloadPartition({ filePath }).then(res => {
      if (res.error === null) {
        addNotification('Le téléchargement du fichier a commencé', 'infos')
        downloadFileBrowser(res.data, filePath)
      }
    }).catch(() => {
      addNotification('Une erreur s\'est produite lors du téléchargement du fichier', 'error')
    })
  }

  useEffect(() => {
    setUpStudents()
    fetchClasses().then(res => {
      if (res.error === null) {
        setClasses(res.data)
      }
    }).catch(() => {
    })
  }, [])

  const filterStudents = (): any => {
    if (filters.name.trim().length === 0 && filters.classe === null) {
      return students
    }

    if (!Array.isArray(students)) {
      return students
    } else {
      return students.filter((student: any) => {
        if (filters.name.trim().length > 0 && student.firstname.toLowerCase().includes(filters.name.toLowerCase()) === false &&
        student.lastname.toLowerCase().includes(filters.name.toLowerCase()) === false &&
        !(student.firstname + ' ' + student.lastname).toLowerCase().includes(filters.name.toLowerCase()) &&
        !(student.lastname + ' ' + student.firstname).toLowerCase().includes(filters.name.toLowerCase())) {
          return false
        }

        if (filters.classe !== null && student.classe?.id !== filters.classe) {
          return false
        }

        return true
      })
    }
  }

  return (
        <StudentsScreen students={students} classes={classes}
        handleCreateNewStudent={handleCreateNewStudent} creatingStatus={newEltState}
        handleDownloadPartitionFile={handleDownloadPartitionFile} filterStudents={filterStudents}
        updateFilters={setFilters} filters={filters} />
  )
}
