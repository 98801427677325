interface InputTextProps {
  placeholder: string | undefined
  name?: string
  value?: string
  onInput?: any
}
export default function InputText ({ placeholder, name, value, onInput }: InputTextProps): JSX.Element {
  return (
    <>
        <input type='text' placeholder={placeholder} name={name} defaultValue={value}
        className="outline-none py-2 px-3 rounded-lg text-sm w-full
        border-slate-100 border-1" onInput={onInput}/>
    </>
  )
}
