import { faShield, faTrash, faUnlockKeyhole, faUser, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubTitle from '../components/Title/Subtitle'
import AdminAuthorityVector from '../assets/images/admin_authority.png'
import Button from '../components/Button/Button'
import Dropdown from '../components/Dropdown/Dropdown'
import InputEmail from '../components/InputEmail/InputEmail'
import InputText from '../components/InputText/InputText'
import { useState } from 'react'
import FetchDataError from '../components/Error/FetchDataError'
import SplashComponent from '../components/Loading/SplashComponent'

export default function UserScreen ({
  userProfile,
  handleUpdateUserProfile,
  classes,
  departements,
  lessons,
  teacherClassesAccess,
  handleAddNewAccessPermission,
  handleDeletePermission
}: any): JSX.Element {
  return (
        <div>
            <div></div>
            <div className='w-full mt-6 grid grid-cols-3 gap-2'>
                <div className="col-span-3 lg:col-span-1 xl:col-span-1 2xl:col-span-1">
                    <div className="w-full shadow-lg rounded-lg p-4 py-14">
                        <div className="text-center">
                            <div><FontAwesomeIcon
                            className="rounded-full bg-firstColor text-white p-5 w-14 h-14"
                            icon={faUser} /></div>
                            <p className='font-bold text-xl uppercase tracking-wide'>{`${userProfile.firstname} ${userProfile.lastname}`}</p>
                            <p className='text-sm text-gray-500'>
                            {userProfile.role === 'admin' && <FontAwesomeIcon icon={faShield} className='mr-1' /> }
                            {userProfile.role === 'admin' ? 'Administrateur' : 'Enseignant'}</p>
                            <p className='pt-6 text-xs text-gray-400 italic'>Créé le {(new Date(userProfile.user.created_at)).toLocaleDateString()}</p>
                        </div>
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <div className='w-full mb-10'>
                        <SubTitle>Information personnelles</SubTitle>
                        <form onSubmit={handleUpdateUserProfile}>
                        <div className='grid grid-cols-2 gap-2'>
                            <input type='hidden' name='userId' value={userProfile.id} />
                            <div className='col-span-1'><InputText name='firstname' placeholder='Prénom' value={userProfile.firstname} /></div>
                            <div className='col-span-1'><InputText name='lastname' placeholder='Nom' value={userProfile.lastname} /></div>
                            <div className='col-span-1'><InputEmail placeholder='E-mail' disableAutocomplete value={userProfile.user.email} name='email' /></div>
                            <div className='col-span-1'><InputText placeholder='Mot de passe' name='password'/></div>
                            <div className='col-span-1'><Dropdown name='role' options={[
                              { value: 'teacher', label: 'Enseignant' },
                              { value: 'admin', label: 'Administrateur' }
                            ]} placeholder='Rôle' defaultValue={userProfile.role} /></div>
                            <div className='col-span-1'><Button title='Mettre à jour' /></div>
                        </div>
                        <div className='px-4 py-3 shadow-md my-3 rounded-md bg-sky-100'>
                          <ul className='list-disc'>
                            <li>
                              <p className='text-xs text-gray-600'>Pour mettre à jour l&apos;adresse e-mail de l&apos;utilisateur, veuillez saisir un nouveau mot de passe conforme à nos recommandations de sécurité.</p>
                            </li>
                            <li>
                            <p className='text-xs text-gray-600'>
                          Pour renforcer la sécurité de vos comptes, nous vous recommandons d&apos;utiliser un mot de passe de minimum 8 caractères contenant au moins une majuscule, une minuscule, un chiffre et un caractère spécial.
                          </p>
                            </li>
                          </ul>
                        </div>
                        </form>
                    </div>
                    <div className='w-full'>
                        <SubTitle>Gérer les autorisations</SubTitle>
                        {userProfile.role === 'admin' && (<div className='text-center'>
                            <img src={AdminAuthorityVector} className='w-64 mx-auto' />
                            <p className='mx-auto uppercase text-xs'>L&apos;administrateur a accès à toutes les fonctionnalités de la plateforme.</p>
                        </div>)}
                        {userProfile.role === 'teacher' &&
                        <TeacherClassesAccessComponent
                        classes={classes}
                        departements={departements}
                        lessons={lessons}
                        teacherClassesAccess={teacherClassesAccess}
                        handleAddNewAccessPermission={handleAddNewAccessPermission}
                        handleDeletePermission={handleDeletePermission}
                        />}
                    </div>
                </div>
            </div>

        </div>
  )
}

function TeacherClassesAccessComponent ({
  departements,
  lessons,
  classes,
  teacherClassesAccess,
  handleAddNewAccessPermission,
  handleDeletePermission
}: any): JSX.Element {
  const [selectedDep, setSelectedDep] = useState<any>(null)
  const [selectedLesson, setSelectedLesson] = useState<any>(null)

  return (
        <div className='container'>
            <div className='p-4 bg-gray-200 rounded-lg shadow-md'>
                <form onSubmit={handleAddNewAccessPermission}>
                <p className='text-sm text-gray-800 mb-2'><FontAwesomeIcon icon={faUnlockKeyhole} className='mr-1' />
                Ajouter une nouvelle autorisation</p>
                <div className='grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4'>
                    <div className='col-span-1'>
                        <p className='text-xs mb-1'>Département</p>
                        {departements !== 'pending' && departements !== 'error' &&
                        <Dropdown options={departements !== 'pending' && departements !== 'error'
                          ? departements.map((dep: any) => (
                            { value: dep.id, label: dep.name }
                          ))
                          : []} placeholder='Département'
                          onChange={(e: any) => { setSelectedDep(e.target.value) }} />}
                          <FetchDataError cond={departements === 'error'}
                          text={`Nous n'avons pas pu récupérer la liste des départements, 
                          l'ajout de nouvelles autorisation sera impossible!`} />
                          <SplashComponent cond={departements === 'pending'} />
                    </div>
                    <div className='col-span-1'>
                        <p className='text-xs mb-1'>Cours</p>
                        {
                        lessons !== 'pending' && lessons !== 'error' &&
                        <Dropdown options={
                            (selectedDep === null
                              ? lessons
                              : lessons.filter((lesson: any) =>
                                Number(lesson.departement_id) === Number(selectedDep)
                              ))
                              .map((lesson: any) => (
                                { value: lesson.id, label: lesson.name }
                              )
                              )
                        } placeholder='Cours' onChange={(e: any) => { setSelectedLesson(e.target.value) }}/>
                        }
                        <FetchDataError cond={lessons === 'error'}
                          text={`Nous n'avons pas pu récupérer 
                          la liste des cours, l'ajout de nouvelles autorisation sera impossible!`} />
                          <SplashComponent cond={lessons === 'pending'} />
                    </div>
                    <div className='col-span-1'>
                        <p className='text-xs mb-1'>Classe</p>
                        {
                        classes !== 'pending' && classes !== 'error' &&
                        teacherClassesAccess !== 'pending' && teacherClassesAccess !== 'error' &&
                        <Dropdown options={
                            (selectedLesson === null
                              ? classes.filter((classe: any) =>
                                (teacherClassesAccess.map((elt: any) => elt.classe?.id)).includes(classe.id) === false
                              )
                              : classes.filter((classe: any) =>
                                Number(classe.lesson_id) === Number(selectedLesson) &&
                                (teacherClassesAccess.map((elt: any) => elt.classe?.id)).includes(classe.id) === false
                              )
                            ).map((classe: any) => (
                              { value: classe.id, label: classe.name }
                            )
                            )
                        } placeholder='Classe' name='classeId'/>
                        }
                        <FetchDataError cond={classes === 'error'}
                          text={`Nous n'avons pas pu récupérer 
                          la liste des classes, l'ajout de nouvelles autorisation sera impossible!`} />
                          <SplashComponent cond={classes === 'pending'} />
                    </div>
                </div>
                <div className='mt-4'>
                    <div className='text-right'>
                        <Button title="Autoriser l'accès" />
                    </div>
                </div>
                </form>
            </div>
            <div className='mt-4 w-full overflow-x-auto'>
                <table>
                    <thead>
                        <tr>
                            <th>Nom de la classe</th>
                            <th>Cours</th>
                            <th>Département</th>
                            <th>Date d&apos;ajout</th>
                            <th>Année</th>
                            <th><FontAwesomeIcon icon={faTrash} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!['pending', 'error'].includes(teacherClassesAccess) &&
                        teacherClassesAccess.map((teacherClasseAccess: any) => (
                        <tr key={teacherClasseAccess.id}>
                            <td>{teacherClasseAccess.classe?.name ?? 'N/A'}</td>
                            <td>{teacherClasseAccess.classe?.lesson?.name ?? 'N/A'}</td>
                            <td>{teacherClasseAccess?.classe?.lesson?.departement?.name ?? 'N/A'}</td>
                            <td>{(new Date(teacherClasseAccess.created_at).toLocaleDateString())}</td>
                            <td>{teacherClasseAccess?.classe?.schoolyear?.name ?? 'N/A'}</td>
                            <td><FontAwesomeIcon icon={faXmark}
                            className='cursor-pointer' onClick={() => { handleDeletePermission(teacherClasseAccess.id) }}/></td>
                        </tr>
                        ))

                        }
                    </tbody>
                </table>
                <FetchDataError cond={teacherClassesAccess === 'error'}
                text={`Nous n'avons pas pu récupérer 
                la liste des permissions déjà ajoutées!`} />
                <SplashComponent cond={teacherClassesAccess === 'pending'} />
            </div>
        </div>
  )
}
