import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../components/Button/Button'
import InputText from '../components/InputText/InputText'
import { faArrowLeft, faCalendarCheck, faPen, faPlus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import SubTitle from '../components/Title/Subtitle'
import SplashComponent from '../components/Loading/SplashComponent'
import FetchDataError from '../components/Error/FetchDataError'
import { useEffect, useState } from 'react'

export default function SchoolYearScreen ({
  schoolyears,
  handleCreateNewSchoolYear,
  selectedSchoolYear,
  setSelectedSchoolYear,
  handleUpdateSchoolYearName,
  handleMarkSchoolYear,
  activeSchoolYear
}: any): JSX.Element {
  const [isActiveYear, setIsActiveYear] = useState('no')

  useEffect(() => {
    setIsActiveYear(selectedSchoolYear?.is_active === true ? 'yes' : 'no')
  }, [selectedSchoolYear])

  if (schoolyears === 'pending') {
    return <SplashComponent />
  }
  if (schoolyears === 'error') {
    return <FetchDataError />
  }
  return (
    <div className='container'>
        <div className='grid grid-cols-3 p-4 mb-3 rounded-xl items-center gap-4'>
            <div className='col-span-3 lg:col-span-1 xl:col-span-1 2xl:col-span-1 py-8'>
                {activeSchoolYear !== null && <div className='text-center opacity-40 w-full'>
                    <p className='text-5xl'>{activeSchoolYear?.name ?? 'N/A'}
                    <span className="absolute  h-3 w-3">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-firstColor opacity-75"></span>
                        <span className="absolute inline-flex rounded-full h-3 w-3 bg-firstColor"></span>
                    </span>
                    </p>
                    <p className='text-sm uppercase mt-2'>année en cours</p>
                </div>}
                {activeSchoolYear === null && <div className='w-full'>
                    <p className='text-sm text-red-500'>
                        <FontAwesomeIcon icon={faTriangleExclamation} className='mr-1' />
                        Année scolaire en cours non défini.</p>
                </div>}
            </div>
            <div className='col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2'>
                <form onSubmit={handleCreateNewSchoolYear}>
                    <p className='text-sm pb-2'>
                        <FontAwesomeIcon icon={faPlus} className='mr-1'/>
                        Créer une nouvelle année scolaire.
                    </p>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='col-span-1'><InputText placeholder='Nom' name='name' /></div>
                        <div className='col-span-1'><Button title='Créer' />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <hr className='w-4/5 mx-auto'/>
        {selectedSchoolYear === null &&
        <div className='pt-4 w-full overflow-x-auto '>
            <SubTitle>Années ajoutées</SubTitle>
            {schoolyears.length === 0 &&
            <div className='py-4 px-2'>
                <p className='text-sm'>Vous n&apos;avez pas encore ajouté d&apos;années scolaires dans le système.</p>
            </div>}
            <div className='pl-4'>
                <ul className='list-disc inline-block'>
                    {schoolyears.map((year: any) => (
                        <li key={year.id}
                        onClick={() => { setSelectedSchoolYear(year) }}
                        className='my-2 bg-gray-100 p-4 hover:bg-gray-200 cursor-pointer'>
                            {year.name}
                            {year.is_active === true &&
                            <span className='text-xs bg-gray-300 text-gray px-2 py-1 rounded-full ml-2'>Annéee en cours
                                <span className="absolute  h-3 w-3">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-firstColor opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-firstColor"></span>
                                </span>
                            </span>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        </div>}
        {selectedSchoolYear !== null &&
        <div className='pt-4 w-full overflow-x-auto'>
            <SubTitle>Gérer l&apos;année</SubTitle>
            <p className='text-sm cursor-pointer font-bold pb-4'
            onClick={() => { setSelectedSchoolYear(null) }}>
                <FontAwesomeIcon icon={faArrowLeft} className='mr-1' />
                Fermer la fiche
            </p>
            <p className='text-md'>{selectedSchoolYear?.name}
            {selectedSchoolYear?.is_active === true &&
             <span className='text-xs bg-gray-500 text-white px-2 py-1 rounded-full ml-2'>Annéee en cours
                <span className="absolute  h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-firstColor opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-firstColor"></span>
                </span>
            </span>}
            </p>
            <div className='grid grid-cols-3 p-4 mb-3 rounded-xl items-center'>
                <div className='col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2'>
                    <form onSubmit={handleUpdateSchoolYearName}>
                        <p className='text-sm pb-2'>
                            <FontAwesomeIcon icon={faPen} className='mr-1'/>
                            Mettre à jour le nom
                        </p>
                        <div className='grid grid-cols-2 gap-2'>
                            <div className='col-span-1'><InputText placeholder='Nom' value={selectedSchoolYear?.name} name='name' /></div>
                            <div className='col-span-1'><Button title='Mettre à jour' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className='grid grid-cols-3 p-4 mb-3 shadow-lg rounded-xl items-center'>
                <div className='col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2'>
                    <form onSubmit={handleMarkSchoolYear}>
                        <p className='text-sm pb-2'>
                            <FontAwesomeIcon icon={faCalendarCheck} className='mr-1'/>
                            Marquer comme année courante
                        </p>
                        <div className='w-full'>
                                <div className='mb-2'>
                                    <input type='radio'
                                    onChange={() => { setIsActiveYear('no') }}
                                    name='setactiveyear' value='no' id='setactiveyear_no'
                                    checked={isActiveYear === 'no'} />
                                    <label className='text-sm pl-1' htmlFor='setactiveyear_no'>Ne pas marquer comme année courante</label>
                                </div>
                                <div className='mb-2'>
                                    <input type='radio' name='setactiveyear' value='yes'
                                    id='setactiveyear_yes'
                                    onChange={() => { setIsActiveYear('yes') }}
                                     checked={isActiveYear === 'yes'} />
                                    <label className='text-sm pl-1' htmlFor='setactiveyear_yes'>Marquer comme année courante</label>
                                </div>

                        </div>
                        <Button title='Sauvegarder' className='mt-2' />
                    </form>
                </div>
            </div>
        </div>}
    </div>
  )
}
