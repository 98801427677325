import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

interface ButtonProps {
  title: string
  onClick?: () => void
  isLoading?: boolean
  loadingText?: string
  className?: string
  icon?: any
  asLabel?: boolean
}

export default function Button ({
  asLabel = false,
  title, onClick,
  isLoading = false,
  loadingText = 'Chargement ...',
  className = '',
  icon
}: ButtonProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleButtonClick = (): void => {
    if (!isLoading && (onClick != null)) {
      onClick()
    }
  }

  const Element: any = asLabel ? 'label' : 'button'

  return (
    <Element
      className={`px-4 py-2 text-sm  font-light text-white bg-firstColor 
      rounded-md transition-opacity hover:opacity-95 
      hover:duration-200 hover:ease-in-out active:scale-95  
      ${isLoading ? 'cursor-not-allowed' : ''}
      ${className}`}
      onClick={handleButtonClick}
      disabled={isButtonDisabled || isLoading}
    >
      {isLoading
        ? (
        <div className="flex items-center justify-center">
          <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2" />
          {loadingText}
        </div>
          )
        : (
            <>{icon !== null && icon !== undefined
              ? <FontAwesomeIcon icon={icon} className='mx-1' />
              : null}
              {title}
              </>

          )}
    </Element>
  )
}
