import create from 'zustand'

type NotificationType = 'success' | 'warning' | 'error' | 'infos'

interface Notification {
  message: string
  type: NotificationType
}

interface NotificationStore {
  notifications: Notification[]
  addNotification: (message: string, type: NotificationType) => void
  removeNotification: (message: string) => void
}

export const useNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  addNotification: (message, type) => {
    set((state) => ({
      notifications: [...state.notifications, { message, type }]
    }))

    setTimeout(() => {
      set((state) => ({
        notifications: state.notifications.filter((notification) => notification.message !== message)
      }))
    }, 15000)
  },
  removeNotification: (message) => {
    set((state) => ({
      notifications: state.notifications.filter((notification) => notification.message !== message)
    }))
  }
}))
