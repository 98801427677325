import { supabase } from './authService'

export const downloadFileBrowser = (fileBlob: any, fileName: string): any => {
  const blobURL = window.URL.createObjectURL(fileBlob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = blobURL
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(blobURL)
  document.body.removeChild(a)
}

export const appSignatureText = `L'Ecole Koenig Platform. V. Beta. 
Copyright © ${(new Date()).getFullYear()} - Tout droit réservé.`

export const signInWIthPassword = async (email: string, password: string): Promise<any> => {
  return await supabase.auth.signInWithPassword({
    email,
    password
  })
}
