import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cubes from '../assets/images/cubes.png'
import { faBook, faBuilding, faChalkboard, faMusic, faUserGraduate, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function DashboardScreen ({
  user,
  metrics
}: any): JSX.Element {
  return (
        <div className="container">
          <p className="text-3xl">Bonjour {user?.firstname ?? '-'}!</p>
          <p className='text-lg text-gray-600'>Votre école en un clin d&apos;œil</p>
          <div className="grid grid-cols-1  lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 mt-4">
            <div className={`col-span-1 p-12 ${metrics === 'pending' ? 'animate-pulse' : ''}`}>
                <Link to='/users'><div className='shadow-lg py-12 rounded-xl bg-gray-100 relative transition-opacity hover:opacity-75
      hover:duration-200 hover:ease-in-out' style={{ backgroundImage: `url(${cubes})` }}>
                <div className='absolute -top-5 shadow-xl bg-firstColor w-14 h-14 flex items-center justify-center rounded-full right-0'>
                  <FontAwesomeIcon icon={faUsers} className='text-white' />
                </div>
                  <div className='text-center'>
                      <p className='text-6xl font-bold'><span className='shadow-2xl text-black inline-block min-w-2/5 py-4 px-2 '>{metrics?.users ?? '--'}</span></p>
                      <p className='text-lg text-black font-semibold uppercase mt-2'>Utilisateurs</p>
                  </div>
              </div></Link>
            </div>
            <div className={`col-span-1 p-12 ${metrics === 'pending' ? 'animate-pulse' : ''}`}>
              <Link to='/departments'><div className='shadow-lg py-12 rounded-xl bg-gray-100 relative transition-opacity hover:opacity-75
      hover:duration-200 hover:ease-in-out' style={{ backgroundImage: `url(${cubes})` }}>
                <div className='absolute -top-5 shadow-xl bg-firstColor w-14 h-14 flex items-center justify-center rounded-full right-0'>
                  <FontAwesomeIcon icon={faBuilding} className='text-white' />
                </div>
                  <div className='text-center'>
                      <p className='text-6xl font-bold'><span className='shadow-2xl text-black inline-block min-w-2/5 py-4 px-2'>{metrics?.departements ?? '--'}</span></p>
                      <p className='text-lg text-black font-semibold uppercase mt-2'>Départements</p>
                  </div>
              </div></Link>
            </div>
            <div className={`col-span-1 p-12 ${metrics === 'pending' ? 'animate-pulse' : ''}`}>
              <Link to='/lessons'><div className='shadow-lg py-12 rounded-xl bg-gray-100 relative transition-opacity hover:opacity-75
      hover:duration-200 hover:ease-in-out' style={{ backgroundImage: `url(${cubes})` }}>
              <div className='absolute -top-5 shadow-xl bg-firstColor w-14 h-14 flex items-center justify-center rounded-full right-0'>
                  <FontAwesomeIcon icon={faBook} className='text-white' />
                </div>
                  <div className='text-center'>
                      <p className='text-6xl font-bold'><span className='shadow-2xl text-black inline-block min-w-2/5 py-4 px-2'>{metrics?.lessons ?? '--'}</span></p>
                      <p className='text-lg text-black font-semibold uppercase mt-2'>Cours</p>
                  </div>
              </div></Link>
            </div>
            <div className={`col-span-1 p-12 ${metrics === 'pending' ? 'animate-pulse' : ''}`}>
              <Link to='/classes'><div className='shadow-lg py-12 rounded-xl bg-gray-100 relative transition-opacity hover:opacity-75
      hover:duration-200 hover:ease-in-out' style={{ backgroundImage: `url(${cubes})` }}>
                  <div className='absolute -top-5 shadow-xl bg-firstColor w-14 h-14 flex items-center justify-center rounded-full right-0'>
                    <FontAwesomeIcon icon={faChalkboard} className='text-white' />
                  </div>
                  <div className='text-center'>
                      <p className='text-6xl font-bold'><span className='shadow-2xl text-black inline-block min-w-2/5 py-4 px-2'>{metrics?.classes ?? '--'}</span></p>
                      <p className='text-lg text-black font-semibold uppercase mt-2'>Classes</p>
                  </div>
              </div></Link>
            </div>
            <div className={`col-span-1 p-12 ${metrics === 'pending' ? 'animate-pulse' : ''}`}>
              <Link to='/students'><div className='shadow-lg py-12 rounded-xl bg-gray-100 relative transition-opacity hover:opacity-75
      hover:duration-200 hover:ease-in-out' style={{ backgroundImage: `url(${cubes})` }}>
                  <div className='absolute -top-5 shadow-xl bg-firstColor w-14 h-14 flex items-center justify-center rounded-full right-0'>
                    <FontAwesomeIcon icon={faUserGraduate} className='text-white' />
                  </div>
                  <div className='text-center'>
                      <p className='text-6xl font-bold'><span className='shadow-2xl text-black inline-block min-w-2/5 py-4 px-2'>{metrics?.students ?? '--'}</span></p>
                      <p className='text-lg text-black font-semibold uppercase mt-2'>élèves</p>
                  </div>
              </div></Link>
            </div>
            <div className={`col-span-1 p-12 ${metrics === 'pending' ? 'animate-pulse' : ''}`}>
              <Link to='/partitions'><div className='shadow-lg py-12 rounded-xl bg-gray-100 relative transition-opacity hover:opacity-75
      hover:duration-200 hover:ease-in-out' style={{ backgroundImage: `url(${cubes})` }}>
                  <div className='absolute -top-5 shadow-xl bg-firstColor w-14 h-14 flex items-center justify-center rounded-full right-0'>
                    <FontAwesomeIcon icon={faMusic} className='text-white' />
                  </div>
                  <div className='text-center'>
                      <p className='text-6xl font-bold'><span className='shadow-2xl text-black inline-block min-w-2/5 py-4 px-2'>{metrics?.partitions ?? '--'}</span></p>
                      <p className='text-lg text-black font-semibold uppercase mt-2'>Prénoms</p>
                  </div>
              </div></Link>
            </div>
          </div>
        </div>
  )
}
