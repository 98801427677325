import { useState } from 'react'

interface InputEmailProps {
  placeholder: string | undefined
  enableInputCheck?: boolean
  name?: string
  disableAutocomplete?: boolean
  value?: string
  disabled?: boolean
}
export default function InputEmail ({
  placeholder,
  enableInputCheck = false,
  name,
  disableAutocomplete,
  value,
  disabled = false
}: InputEmailProps): JSX.Element {
  const [inputValid, setInputValid] = useState(true)
  const checkInputPattern = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

    setInputValid(emailPattern.test(event.target.value))
  }

  return (
      <>
        <input type='email' placeholder={placeholder} name={name} defaultValue={value}
        className={`outline-none py-2 px-3 rounded-lg text-sm w-full
        border-slate-100 border-1 ${disabled ? 'text-gray-500 cursor-not-allowed' : ''}`} disabled={disabled}
        autoComplete={(disableAutocomplete ?? false) ? 'off' : ''}
        onInput={checkInputPattern }/>
        {enableInputCheck && !inputValid && <p className="text-xs pt-1 text-red-500">Veuillez saisir une adresse e-mail valide.</p>}
      </>
  )
}
