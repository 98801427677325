import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ActionInfoProps {
  icon: IconProp
  info: string
}
export default function ActionInfo ({ icon, info }: ActionInfoProps): JSX.Element {
  return (
        <div className='col-span-3 lg:col-span-1 xl:col-span-1 2xl:col-span-1 text-center mb-2'>
            <FontAwesomeIcon icon={icon} className='w-9 h-9 p-4 shadow-lg text-firstColor mb-2 rounded-full' />
            <p className='text-xs text-gray-600'><FontAwesomeIcon icon={faInfoCircle}
            className='mr-1 text-gray-400 w-4 h-4 -mb-0.5'
            />{info}.</p>
        </div>
  )
}
