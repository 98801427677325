import { useEffect, useState } from 'react'

interface DropdownProps {
  options: Array<{ value: string, label: string }>
  name?: string
  placeholder: string | undefined
  defaultValue?: string
  onChange?: any
  nullable?: boolean
}

export default function Dropdown ({
  options,
  name,
  placeholder,
  defaultValue,
  onChange,
  nullable
}: DropdownProps): JSX.Element {
  const [searchText, setSearchText] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)

  useEffect(() => {
    if (searchText.trim() === '') {
      setFilteredOptions(options)
    } else {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      )
      setFilteredOptions(filtered)
    }
  }, [searchText, options])

  return (
    <div className='w-full border-gray-100 border-b-1 rounded-lg'>
      {nullable !== true && <p className='text-sm text-gray-800 pl-3'>{placeholder}</p>}
      <input
        type="text"
        placeholder="Chercher dans la liste..."
        value={searchText}
        onChange={(e) => { setSearchText(e.target.value) }}
        className="outline-none py-2 px-3  text-xs w-full"
      />
      <select
        defaultValue={defaultValue}
        name={name}
        className="outline-none py-2 px-3  text-sm w-full"
        onChange={onChange}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {nullable === true &&
        <option key='NOTIMPORTANT' value='none'>{placeholder ?? 'Veuillez choisir'}</option>}
        {filteredOptions.map((option) => (
          <option key={option.value} value={option.value} selected={defaultValue === option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
