import { useState } from 'react'

interface InputPasswordProps {
  placeholder: string | undefined
  enableInputCheck?: boolean
  name?: string
  disableAutoComplete?: boolean
  disabled?: boolean
  defaultValue?: string
}

export default function InputPassword ({
  placeholder,
  enableInputCheck = false,
  name,
  disableAutoComplete,
  disabled = false,
  defaultValue = ''
}: InputPasswordProps): JSX.Element {
  const [inputValid, setInputValid] = useState(true)

  const checkInputPattern = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/

    setInputValid(passwordPattern.test(event.target.value))
  }

  return (
    <>
      <input type="password" name={name} placeholder={placeholder} disabled={disabled}
        className={`outline-none py-2 px-3 rounded-lg text-sm w-full border-slate-100 border-1 
        ${disabled ? 'text-gray-500 cursor-not-allowed' : ''}`}
        autoComplete={(disableAutoComplete ?? false) ? 'new-password' : ''}
        defaultValue={defaultValue}
        onInput={checkInputPattern}
      />
      {enableInputCheck && !inputValid && (
        <p className="text-xs pt-1 text-red-500">
          Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule, une lettre minuscule et un chiffre.
        </p>
      )}
    </>
  )
}
