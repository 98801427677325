/* eslint-disable @typescript-eslint/no-unused-vars */
import { faArrowDown, faArrowRight, faBook, faBuilding, faChalkboard, faFileAudio, faFileDownload, faMusic, faPlayCircle, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubTitle from '../components/Title/Subtitle'
import Button from '../components/Button/Button'
import InputText from '../components/InputText/InputText'
import { useEffect, useState } from 'react'

import Dropdown from '../components/Dropdown/Dropdown'
import SplashComponent from '../components/Loading/SplashComponent'
import Page404Error from '../components/Error/Page404Error'
import { fetchPartitionFilePublicURL } from '../services/adminService'
import FetchDataError from '../components/Error/FetchDataError'
import { Link } from 'react-router-dom'

export default function ClasseScreen ({
  classe,
  lessons,
  handleDownloadStudentPartition,
  handleGetClassePartition,
  handleUpdateClasse,
  user,
  handleDeleteClasse
}: any): JSX.Element {
  const [displayPartitionRead, setDisplayPartitionReader] = useState(false)
  const [displayPlaylistReader, setDisplayPlaylistReader] = useState(false)

  if (classe === 'pending') {
    return <SplashComponent />
  } if (classe === 'error') {
    return <FetchDataError />
  } else if (classe === 'notfound') {
    return <Page404Error />
  } else {
    if (displayPartitionRead) {
      return <PartitionReader data={null} closeReader={() => { setDisplayPartitionReader(false) }}
        handleGetClassePartition={handleGetClassePartition} />
    } else if (displayPlaylistReader) {
      return <PlaylistReader classe={classe} closeReader={() => { setDisplayPlaylistReader(false) }} />
    } else {
      return (
        <div>
            <div></div>
            <div className='w-full mt-6 grid grid-cols-3 gap-2'>
                <div className="col-span-3 lg:col-span-1 xl:col-span-1 2xl:col-span-1">
                    <div className="w-full shadow-lg rounded-lg p-4 py-14">
                        <div className="text-center">
                            <div><FontAwesomeIcon
                            className="rounded-full bg-firstColor text-white p-5 w-14 h-14"
                            icon={faChalkboard} /></div>
                            <p className='font-bold text-xl uppercase tracking-wide'>{classe.name}</p>
                            <p className='text-sm text-gray-500'>
                            </p>
                            <p className='pt-2 text-xs text-gray-400 italic'>{classe?.subscriptions?.length ?? 0} élèves </p>
                        </div>
                        <div className='px-4 mt-3 text-xs text-gray-500'>
                            <p><FontAwesomeIcon icon={faBuilding} className='mr-1 text-firstColor' />
                            <span className='font-bold'>Département</span>
                            <FontAwesomeIcon icon={faArrowRight} className='mr-2 ml-1 text-secondColor' />
                            {classe.lesson?.departement?.name}
                            </p>
                            <p className='mt-2'><FontAwesomeIcon icon={faBook} className='mr-1 text-firstColor' />
                            <span className='font-bold'>Cours</span>
                            <FontAwesomeIcon icon={faArrowRight} className='mr-2 ml-1 text-secondColor' />
                            {classe.lesson?.name}
                            </p>
                            <p className='mt-2'><FontAwesomeIcon icon={faMusic} className='mr-1 text-firstColor' />
                            <span className='font-bold'>Partition musicale</span>
                            <FontAwesomeIcon icon={faArrowDown} className='mr-2 ml-1 text-secondColor -rotate-45' />
                            <p className='text-center mt-2 text-lg'>
                                <Button title='Télécharger' icon={faFileDownload} onClick={handleGetClassePartition}
                                className='m-1' />
                                <Button title='Lire' icon={faPlayCircle} onClick={() => { setDisplayPartitionReader(true) }}
                                className='m-1' />
                                <Button title='Playlist' icon={faFileAudio} onClick={() => { setDisplayPlaylistReader(true) }}
                                className='m-1' />
                            </p>
                            </p>
                        </div>

                        {user.role === 'admin' &&
                        <div className='mt-6 text-center'>
                            <Button icon={faXmark} title='' onClick={handleDeleteClasse} />
                        </div>}
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <div className='w-full mb-10'>
                        {user?.role === 'admin' &&
                        <>
                        <SubTitle>Informations de base</SubTitle>
                        <form onSubmit={handleUpdateClasse}>
                            <div className='grid grid-cols-2 gap-2'>
                                <div className='col-span-1'><InputText name='name' placeholder='Nom' value={classe.name} /></div>
                                <div className='col-span-1'>
                                    <Dropdown name='lesson_id' placeholder='Cours' options={lessons} defaultValue={classe.lesson?.id} />
                                </div>
                                <div className='col-span-1'></div>
                                <div className='col-span-1'><Button title='Mettre à jour' /></div>
                            </div>
                        </form>
                        <div className='w-full h-'></div>
                        <div className='w-full h-4'></div>
                        </>
                        }
                        <SubTitle>Élèves</SubTitle>
                        <div className='w-full overflow-x-auto'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Prénom</th>
                                        <th>Nom</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {classe?.subscriptions?.map((subscription: any) => (
                                        <tr key={subscription.id}>
                                            <td className={`${user.role === 'admin' ? 'font-bold' : ''}`}>
                                                {user.role === 'admin'
                                                  ? <Link to={`/students/${subscription?.student.id}`}>{subscription?.student.firstname}</Link>
                                                  : subscription?.student.lastname}
                                                </td>
                                            <td>{subscription?.student.lastname}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      )
    }
  }
}

function PartitionReader ({ data, closeReader, handleGetClassePartition }: any): JSX.Element {
  const [html, setHtml] = useState<any>(null)

  useEffect(() => {
    handleGetClassePartition(false).then((res: any) => {
      setHtml(res.html)
    }).catch(() => {
      setHtml('error')
    })
  }, [])
  return (
    <div className='w-full'>
        <div className='text-right'>
            <Button icon={faXmark} title='Quitter' onClick={closeReader}></Button>
        </div>
        <div className='mt-4'>
            {html !== null && <div dangerouslySetInnerHTML={{ __html: html }}></div>}
            <SplashComponent cond={html === null} />
        </div>
    </div>
  )
}

function PlaylistReader ({ classe, closeReader }: any): JSX.Element {
  const [dataDownloaded, setDataDownloaded] = useState(false)
  useEffect(() => {
    void setupUrls()
  }, [])

  const setupUrls = async (): Promise<any> => {
    for (let i = 0; i < classe?.subscriptions?.length; i++) {
      const { error: fetchImgError, data: fetchImgData } =
      await fetchPartitionFilePublicURL(classe.subscriptions[i]?.student?.partition?.file_path)
      const { error: fetchAudioError, data: fetchAudioData } =
      await fetchPartitionFilePublicURL(classe.subscriptions[i]?.student?.partition?.file_audio_path)

      if (fetchImgError === undefined) { classe.subscriptions[i].student.imgUrl = fetchImgData.publicUrl }
      if (fetchAudioError === undefined) { classe.subscriptions[i].student.audioUrl = fetchAudioData.publicUrl }
    }
    setDataDownloaded(true)
  }
  if (!dataDownloaded) {
    return <SplashComponent />
  } else {
    return (
    <div className='w-full'>
    <div className='text-right'>
        <Button icon={faXmark} title='Quitter' onClick={closeReader}></Button>
    </div>
    <div className='mt-4'>
        <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4'>
            {classe.subscriptions.map((subscription: any) => (
                <div className='col-span-1' key={subscription?.student.id}>
                <div className="max-w-md mx-auto bg-white rounded-xl overflow-hidden md:max-w-2xl my-4">
                    <div className="md:flex">
                        <div className="md:flex-shrink-0">
                        <img className="h-36 w-full object-cover md:w-36"
                        src={subscription?.student.imgUrl} alt="Partition" />
                        </div>

                        <div className="p-2">
                        <div className="uppercase tracking-wide text-xs text-firstColor font-semibold">{subscription?.student.firstname} {subscription?.student.lastname}</div>
                        <a href="#" className="block mt-1 text-sm leading-tight font-medium text-black hover:underline">{subscription?.student.partition?.name ?? 'N/A'}</a>

                        <div className="mt-4">
                            <audio controls src={subscription?.student.audioUrl}></audio>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            ))}
        </div>
    </div>
    </div>
    )
  }
}
