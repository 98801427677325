import Logo from '../assets/images/logo.svg'
import Button from '../components/Button/Button'
import InputPassword from '../components/InputPassword/InputPassword'
import InputEmail from '../components/InputEmail/InputEmail'
import { appSignatureText } from '../services/browserService'
// import cleanGrayPaper from '../assets/images/clean-gray-paper.png//'

export default function LoginScreen (props: any): JSX.Element {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4">
      <div></div>
      <div className="col-span-1 lg:col-span-2 xl:col-span-2 2xl:col-span-2 bg-slate-50 p-3
      rounded-xl shadow-2xl p-10">
        <div className="">
          <img src={Logo} className="bg-firstColor p-4 w-24 lg:p-8 lg:w-36 xl:p-8 xl:w-36 2xl:p-8 2xl:w-36" />
        </div>
        <div className='py-2'>
          <p className='font-light pb-2'>Une connexion est requise pour acceder à la plateforme.</p>
          <form onSubmit={props.submitLoginForm}>
            <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4'>
              <div className='col-span-2 lg-col-span-1 xl:col-span-1 2xl:col-span-1'>
                <InputEmail placeholder='Votre adresse e-mail' name='email' disableAutocomplete={true} />
              </div>
              <div className='col-span-2 lg-col-span-1 xl:col-span-1 2xl:col-span-1'>
                <InputPassword placeholder='Mot de passe' name='password' disableAutoComplete={true} />
              </div>
              <div className='col-span-2 lg-col-span-1 xl:col-span-1 2xl:col-span-1'></div>
              <div className='col-span-2 lg-col-span-1 xl:col-span-1 2xl:col-span-1 flex justify-end'>
                <Button title='Se connecter' isLoading={props.buttonIsLoading} loadingText='connexion ...' />
              </div>
            </div>
          </form>
          <div className='flex justify-center mt-4'>
            {props.loginErrorMessage !== null && <p className='text-sm text-red-500'>{props.loginErrorMessage}</p> }
          </div>
          <div className='my-4'>
          </div>
        </div>
        <div className='flex justify-end'>
          <p className='text-xs text-slate-500'>{appSignatureText}</p>
        </div>
       </div>
    </div>
  )
}
