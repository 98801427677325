import { createClient } from '@supabase/supabase-js'
// import { Auth } from '@supabase/auth-ui-react'
// import { ThemeSupa } from '@supabase/auth-ui-shared'
import useAuthStore from '../store/authStore'
import { useState, useEffect } from 'react'

const AUTH_STORAGE_KEY = 'auth_user'

export const userIsAuthenticated = (): boolean => {
  const authUserData = localStorage.getItem(AUTH_STORAGE_KEY)

  if (authUserData != null) {
    // const authInfo = JSON.parse(authUserData)
    useAuthStore.setState({ user: authUserData })
    return true
  }
  useAuthStore.setState({ user: null })
  return false
}

export const loginUser = (userData: any): void => {
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(userData))
  useAuthStore.setState({ user: userData })
}

export const supabase = createClient(`${process.env.REACT_APP_SUPABASE_URL}`, `${process.env.REACT_APP_SUPABASE_ANO}`)

export type AuthState = 'authenticated' | 'unauthenticated' | 'checking'

export const userIsAuthenticatedSupabase = (): AuthState => {
  const [authState, setAuthState] = useState<AuthState>('checking')

  useEffect(() => {
    supabase.auth.onAuthStateChange((_event, session) => {
      if (session != null) {
        setAuthState('authenticated')
      } else {
        setAuthState('unauthenticated')
      }
    })
  }, [])

  return authState
}

export const logoutUser = async (): Promise<void> => {
  await supabase.auth.signOut()
}

export const getUser = async (): Promise<any> => {
  return await supabase.auth.getUser()
}
