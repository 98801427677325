/* eslint-disable @typescript-eslint/no-misused-promises */
import { faArrowLeft, faBook, faBuilding, faCalendar, faChalkboard, faHome, faMusic, faUserCircle, faUserGraduate, faUsers, faXmark }
  from '@fortawesome/free-solid-svg-icons'
import LogoRed from '../../assets/images/logo_red.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { logoutUser } from '../../services/authService'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import clearGrayPaper from '../../assets/images/clean-gray-paper.png'
import { appSignatureText } from '../../services/browserService'
import { useSchoolYear } from '../../context/SchoolYearContext'

export default function Header (props: any): JSX.Element {
  const [menu, setMenu] = useState<any[]>([])
  const { activeSchoolYear } = useSchoolYear()

  const navigate = useNavigate()
  const handleLogout = async (): Promise<void> => {
    try {
      await logoutUser()
      navigate('/login')
    } catch (err) {}
  }
  useEffect(() => {
    if (props.user !== 'pending' && props.user !== 'error') {
      if (props.user.role === 'admin') {
        setMenu([
          { id: 1, name: 'Accueil', path: '/dashboard', icon: faHome },
          { id: 2, name: 'Année scolaire', path: '/school-year', icon: faCalendar },
          { id: 8, name: 'Utilisateurs', path: '/users', icon: faUsers },
          { id: 3, name: 'Départements', path: '/departments', icon: faBuilding },
          { id: 4, name: 'Cours', path: '/lessons', icon: faBook },
          { id: 5, name: 'Classes', path: '/classes', icon: faChalkboard },
          { id: 6, name: 'Élèves', path: '/students', icon: faUserGraduate },
          { id: 7, name: 'Partitions', path: '/partitions', icon: faMusic }
        ])
      } else if (props.user.role === 'teacher') {
        setMenu([
          { id: 1, name: 'Accueil', path: '/dashboard', icon: faHome }
        ])
      }
    }
  }, [props.user])
  return (
        <div className={`fixed flex top-0 left-0 h-full bg-slate-100 overflow-y-auto w-full
        lg:w-1/5 xl:w-1/5 2xl:w-1/5 z-10
        ${props.menuVisible === true ? 'slide-in' : 'hidden'} lg:block xl:block 2xl:block`} style={{ backgroundImage: `url(${clearGrayPaper})` }}>
            <FontAwesomeIcon icon={faXmark}
            className='w-7 h-7 text-gray-500 absolute top-2 right-2 lg:hidden xl:hidden 2xl:hidden'
            onClick={props.closeSlideOnMobile} />
            <div className="pb-0 flex justify-center">
                <img src={LogoRed} className="my-2 rounded-md w-24  lg:w-36  xl:w-36  2xl:w-36" />
            </div>
            <div className='text-center'>
            <p className='text-xs text-gray-600 font-serif'>{activeSchoolYear?.name}</p>
            </div>

            <div className='mt-2'>
            <ul className="px-4 text-sm">
                {menu.map((item) => (
                    <Link to={item.path} key={item.id} onClick={props.closeSlideOnMobile}>
                    <li className={'p-3 mb-0 flex items-center hover:bg-gray-300 hover:rounded-xl ' +
                    (item.name === props.selectedMenu ? 'bg-gray-300 rounded-xl' : '')}>
                        <FontAwesomeIcon icon={item.icon} className='mr-2' />
                        {item.name}
                    </li>
                </Link>
                ))}

            </ul>
            </div>

            <div className={'px-4 pb-2 w-full absolute bottom-2'}>
                <div className='pb-2 px-4'>
                    <div className='text-center bg-gray-300 py-8 rounded-xl'>
                      <p className='text-sm'><FontAwesomeIcon icon={faUserCircle} className='mr-1' />
                      {props.user.firstname + ' ' + props.user.lastname}</p>
                      <p className='text-xs'>{props.user.role === 'teacher' ? 'Enseignant' : 'Administrateur'}</p>
                      <p className='mt-3 text-xs cursor-pointer'
                      onClick={handleLogout}
                      ><FontAwesomeIcon icon={faArrowLeft} className='mr-2' />Se déconnecter</p>
                    </div>
                </div>
                <p className='text-xs text-slate-500 mt-2'>{appSignatureText}</p>
            </div>

        </div>
  )
}
