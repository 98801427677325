import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import 'font-awesome/css/font-awesome.min.css'
import { AuthMiddleware } from './middlewares/auth.middleware'
import { privateRoutes } from './routes/private.route'
import { publicRoutes } from './routes/public.route'
import Page404Error from './components/Error/Page404Error'

function App (): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthMiddleware />}>
          {privateRoutes}
        </Route>
        {publicRoutes}
        <Route path='*' element={<Page404Error />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
