/* eslint-disable @typescript-eslint/no-unused-vars */
import { faArrowDown, faArrowRight, faBuildingUser, faChalkboardUser, faHeartMusicCameraBolt, faInfoCircle, faMusic, faPlayCircle, faShield, faTrash, faTriangleExclamation, faUser, faUsersRectangle, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubTitle from '../components/Title/Subtitle'
import Button from '../components/Button/Button'
import InputText from '../components/InputText/InputText'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from '../components/Dropdown/Dropdown'
import FetchDataError from '../components/Error/FetchDataError'
import SplashComponent from '../components/Loading/SplashComponent'
import Page404Error from '../components/Error/Page404Error'

export default function StudentScreen ({
  student,
  partitions,
  handleDownloadPartitionFile,
  handleUpdateStudent,
  updatePersonalDataStatus,
  handleRemoveDissociatePartition,
  dissociatePartitionStatus,
  handleAssociatePartition,
  associatePartitionStatus,
  handleDeleteStudent,
  checkIfAssociationIsPossible,
  classes,
  handleAffectStudentToAClass,
  activeSchoolYear
}: any): JSX.Element {
  if (student === 'pending') {
    return <SplashComponent />
  } else if (student === 'error') {
    return <FetchDataError />
  } else if (student === 'notfound') {
    return <Page404Error />
  }

  return (
        <div>
            <div></div>
            <div className='w-full mt-6 grid grid-cols-3 gap-2'>
                <div className="col-span-3 lg:col-span-1 xl:col-span-1 2xl:col-span-1">
                    <div className="w-full shadow-lg rounded-lg p-4 py-14">
                        <div className="text-center">
                            <div><FontAwesomeIcon
                            className="rounded-full bg-firstColor text-white p-5 w-14 h-14"
                            icon={faUser} /></div>
                            <p className='font-bold text-xl uppercase tracking-wide'>{`${student.firstname} ${student.lastname}`}</p>
                            <p className='text-sm text-gray-500'>
                            </p>
                            <p className='pt-6 text-xs text-gray-400 italic'>Créé le {(new Date(student.created_at)).toLocaleDateString()}</p>
                        </div>
                        {student.classe !== null &&
                        <div className='px-4 mt-3 text-xs text-gray-500'>
                            <FontAwesomeIcon icon={faBuildingUser} className='mr-1 text-firstColor' />
                            {student.classe?.lesson?.departement?.name}
                            <FontAwesomeIcon icon={faArrowRight} className='mr-2 ml-1 text-secondColor' />
                            <FontAwesomeIcon icon={faHeartMusicCameraBolt} className='mr-1 text-firstColor' />{
                            student.classe?.lesson?.name}
                            <FontAwesomeIcon icon={faArrowRight} className='mr-2 ml-1 text-secondColor' />
                            <FontAwesomeIcon icon={faChalkboardUser} className='mr-1 text-firstColor' />
                            {student.classe?.name}
                        </div>}
                        <div className='mt-6 text-center'>
                            <Button icon={faXmark} title='Supprimer' onClick={handleDeleteStudent} />
                        </div>
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <div className='w-full mb-10'>
                        <SubTitle>Information personnelles</SubTitle>
                        <form onSubmit={handleUpdateStudent}>
                            <div className='grid grid-cols-2 gap-2'>
                                <input type='hidden' name='studentId' value={student.id} />
                                <div className='col-span-1'><InputText name='firstname' placeholder='Prénom' value={student.firstname} /></div>
                                <div className='col-span-1'><InputText name='lastname' placeholder='Nom' value={student.lastname} /></div>
                                <div className='col-span-1'></div>
                                <div className='col-span-1'><Button title='Mettre à jour' isLoading={updatePersonalDataStatus === 'pending'} /></div>
                            </div>
                        </form>
                        <div className='w-full h-'></div>
                        <SubTitle>Classe / Cours</SubTitle>
                        {activeSchoolYear !== null && <div>
                            {student.classe !== null && <p className='text-sm'>
                            {student.firstname} suit le cours&nbsp;
                            <strong>{student.classe?.lesson?.name ?? 'N/A'}</strong>&nbsp;
                            dans la classe de <Link to={`/classes/${student.classe?.id}`}><strong>{student.classe?.name ?? 'N/A'}</strong></Link>
                            </p>}
                            {student.classe === null && <p>{student.firstname}
                            &nbsp;n&apos;est enregsitré actuellement dans aucune classe.</p>}
                            <p className='text-sm mt-2 text-firstColor'>
                                <FontAwesomeIcon icon={faChalkboardUser} className='mr-1' />
                                Affecter à une autre classe
                            </p>
                            <form onSubmit={handleAffectStudentToAClass}>
                                {classes !== 'error' && classes !== 'pending' &&
                                <div className='grid grid-cols-2 gap-2'>
                                    <div className='col-span-1'><Dropdown name='classe_id' options={
                                        classes
                                          .filter((classe: any) => {
                                            return Number(classe?.id) !== Number(student?.classe?.id)
                                          })
                                          .map((classe: any) => (
                                            {
                                              label: `${classe?.name} (${classe?.lesson?.departement?.name ?? 'N/A'})`,
                                              value: classe.id
                                            }
                                          ))
                                    } placeholder='Choisir une classe' />
                                    </div>
                                    <div className='col-span-1'><Button title='Affecter' /></div>
                                </div>}
                            </form>
                        </div>}
                        {activeSchoolYear === null &&
                        <div>
                            <p className='text-sm text-red-500'>
                                <FontAwesomeIcon icon={faTriangleExclamation} className='mr-1' />
                                Aucune année n&apos;est définie comme année courante.
                            </p>
                        </div>}
                        <div className='w-full h-4'></div>
                        <SubTitle>Prénom Musical</SubTitle>
                        {activeSchoolYear !== null && <div>
                            {student.partition === null && <div>
                                <p className='text-sm'>
                                    {student.firstname} ne possède pas encore un prénom musical.
                                </p>
                                <p className='text-sm mt-2 text-firstColor'>
                                    <FontAwesomeIcon icon={faMusic} className='mr-1' />
                                    Affecter un prénom musical
                                </p>
                                {partitions !== 'pending' && partitions !== 'error' &&
                                <form onSubmit={handleAssociatePartition}>
                                    <div className='grid grid-cols-2 gap-2'>
                                        <input type='hidden' name='studentId' value={student.id} />
                                        <div className='col-span-1'><Dropdown name='partition_id' options={
                                        partitions
                                          .map((partition: any) => (
                                            {
                                              label: partition.name,
                                              value: partition.id
                                            }
                                          ))
                                    } placeholder='Choisir un partition' /></div>
                                        <div className='col-span-1'><Button title='Affecter' isLoading={associatePartitionStatus === 'pending'} /></div>
                                    </div>
                                </form>}
                                <FetchDataError cond={partitions === 'error'}
                                text={'Une erreur s\'est produite lors du chargement de la liste des partitions.'} />
                                <SplashComponent cond={partitions === 'pending'} />
                            </div>}
                            {student.partition !== null && <div>
                                <div className='inline-block px-4 py-4 rounded-xl shadow'>
                                    <div className='px-8 text-center'>
                                        <div>
                                            <FontAwesomeIcon icon={faArrowDown} className='w-6 h-6 cursor-pointer'
                                            title='Télécharger la partition en PDF'
                                            onClick={(event) => handleDownloadPartitionFile(student.partition?.file_path)} />
                                        </div>
                                        <p className='text-sm'>{student?.partition.name}</p>
                                    </div>
                                    <div className='mt-4'>
                                        <Button title='Dissocier' icon={faTrash}
                                        onClick={handleRemoveDissociatePartition} isLoading={dissociatePartitionStatus === 'pending'}/>
                                    </div>
                                </div>
                            </div>}
                        </div>}
                        {activeSchoolYear === null &&
                        <div>
                            <p className='text-sm text-red-500'>
                                <FontAwesomeIcon icon={faTriangleExclamation} className='mr-1' />
                                Aucune année n&apos;est définie comme année courante.
                            </p>
                        </div>}
                    </div>
                </div>
            </div>

        </div>
  )
}
