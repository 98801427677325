import { create } from 'zustand'

interface AuthStore {
  user: any
  setUser: (user: any) => void
}

const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  setUser: (user) => { set({ user }) }
}))

export default useAuthStore
