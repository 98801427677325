/* eslint-disable @typescript-eslint/no-unused-vars */
import { faCloudArrowDown, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  text?: string
  cond?: boolean
}

export default function FetchDataError ({ text = 'Une erreur s\'est produite lors du chargement des données', cond = true }: Props): JSX.Element {
  if (cond) {
    return (
          <div className="py-8 bg-white text-center">
            <div className='w-full h-full'>
            <FontAwesomeIcon icon={faCloudArrowDown} className='text-gray-600 w-8 h-8'/>
            <FontAwesomeIcon icon={faXmark} className='text-red-300 w-4 h-4 relative bottom-1 -left-3'/>
            </div>
            <p className="text-center bg-white pt-0 text-xs text-gray-600">{text}</p>
          </div>
    )
  }
  return <></>
}
