import { useNavigate } from 'react-router-dom'
import Button from '../Button/Button'
import { appSignatureText } from '../../services/browserService'

export default function Page404Error ({ cond = true }: any): JSX.Element {
  const navigate = useNavigate()

  return <div className="text-center pt-28">
    <p className="text-9xl">404!</p>
    <p className="font-mono tracking-widest text-lg">Page introuvable</p>
    <Button onClick={() => { navigate(-1) }} title='Retour' className='mt-8 bg-slate-500 px-8 uppercase' />
        <p className='text-xs text-slate-500 mt-2'>{appSignatureText}</p>
    </div>
}
