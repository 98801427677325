import { createContext, useContext, useEffect, useState, type ReactNode } from 'react'
import { getUser } from '../services/authService'
import { fetchProfile } from '../services/adminService'

type User = any

const UserContext = createContext<User | 'pending' | 'error'>(undefined)

interface UserProviderProps {
  children: ReactNode
}

export function UserProvider ({ children }: UserProviderProps): JSX.Element {
  const [user, setUser] = useState<User | 'pending' | 'error'>('pending')

  const setupUser = async (): Promise<any> => {
    const { error: userErr, data: userData } = await getUser()

    if (userErr === null || userErr === undefined) {
      const { error: profileErr, data: profileData } = await fetchProfile(userData.user.id)
      if (profileErr === null || profileErr === undefined) {
        setUser({ ...profileData[0], user: userData.user })
      } else {
        setUser('error')
      }
    } else {
      setUser('error')
    }
  }
  useEffect(() => {
    void setupUser()
  }, [])

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  )
}

export function useUser (): any {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return context
}
