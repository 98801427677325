/* eslint-disable @typescript-eslint/no-unused-vars */
import { faCloudArrowDown, faExclamationTriangle, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  text?: string
  cond?: boolean
}

export default function FormValidationError ({ text, cond = true }: Props): JSX.Element {
  if (cond) {
    return (
            <p className="text-xs my-2">
            <FontAwesomeIcon icon={faExclamationTriangle} className='text-red mr-2 text-red-900 w-5 h-5 -mb-1' />
            <span className='border-b-1 border-firstColor border-dashed'>{text}</span>
            </p>
    )
  }
  return <></>
}
