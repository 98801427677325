import { type UUID } from 'crypto'
import { supabase } from './authService'
import axios from 'axios'

export async function getAllUsers (): Promise<any> {
  const functionUrl = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/get-users`
  const token = process.env.REACT_APP_SUPABASE_ANO

  try {
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: 'Functions' })
    })

    if (!response.ok) {
      throw new Error(`Erreur lors de la requête: ${response.status} - ${response.statusText}`)
    }

    const result = await response.json()
    return result
  } catch (error) {
    console.error('Erreur lors de la récupération des utilisateurs depuis la fonction côté serveur:', error)
    return 'error'
  }
}

export async function createUser ({ email, password, role }: any): Promise<any> {
  const url = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/create-user`
  const token = process.env.REACT_APP_SUPABASE_ANO

  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  }

  const data = {
    email,
    password,
    role
  }

  return await axios.post(url, data, { headers })
}

export async function getUserById (id: any): Promise<any> {
  const url = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/get-user`
  const token = process.env.REACT_APP_SUPABASE_ANO

  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  }

  const data = { id }

  return await axios.post(url, data, { headers })
}

export const fetchUserProfiles = async (): Promise<any> => {
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select('*')

    if (error !== null) {
      throw new Error()
    } else {
      for (let i = 0; i < data.length; i++) {
        try {
          const res = await getUserById(data[i]?.id)
          data[i].user = res.data.user?.user
        } catch (err) {
          throw new Error()
        }
      }
      console.log('profiles : ', data)
      return {
        error: null,
        data
      }
    }
  } catch (error) {
    return {
      error,
      data: null
    }
  }
}

export const fetchProfile = async (userId: UUID): Promise<any> => {
  return await supabase
    .from('profiles')
    .select('*')
    .eq('id', userId)
}

export const fetchUserProfile = async (userId: UUID): Promise<any> => {
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', userId)
    if (error !== null) {
      return data
    }
  } catch (error) {
    return null
  }
}

export const createProfile = async (userId: UUID, firstname: string, lastname: string, role: string): Promise<any> => {
  return await supabase
    .from('profiles')
    .insert({ id: userId, firstname, lastname, role })
    .select('*')
}

const updateUserPassword = async (email: string, password: string, userId: any): Promise<any> => {
  const url = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/update-user-password`
  const token = process.env.REACT_APP_SUPABASE_ANO

  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  }

  const data = { email, password, userId }

  return await axios.post(url, data, { headers })
}

export const updateUserProfile = async (obj: any): Promise<any> => {
  try {
    if (obj.firstname.length > 0 && obj.lastname.length > 0 && ['admin', 'teacher'].includes(obj.role)) {
      const { error } = await supabase
        .from('profiles')
        .update({ firstname: obj.firstname, lastname: obj.lastname, role: obj.role })
        .eq('id', obj.userId)
      if (obj.password.length >= 8) {
        const updatePasswordRes = await updateUserPassword(obj.email, obj.password, obj.userId)
        console.log('updating password  : ', updatePasswordRes)
      }
      if (error === null) {
        return { updated: true }
      } else return { updated: false }
    }
  } catch (error) {
    return { updated: false }
  }
}

export const fetchDepartments = async (): Promise<any> => {
  return await supabase
    .from('departements')
    .select(`
    id,
    name,
    created_at,
    lessons:lessons(id)
    `)
}

export const fetchDepartment = async (id: number): Promise<any> => {
  return await supabase
    .from('departements')
    .select(`
    id,
    name,
    created_at,
    lessons:lessons(*, classes:classes(id, students:students(id)))
    `)
    .eq('id', id)
}

export const updateDepartment = async (id: number, data: any): Promise<any> => {
  return await supabase
    .from('departements')
    .update(data)
    .eq('id', id)
    .select(`
    id,
    name,
    created_at,
    lessons:lessons(id)
    `)
}

export const createNewDepartment = async ({ name }: any): Promise<any> => {
  return await supabase
    .from('departements')
    .insert({ name })
}

export const fetchClasses = async (): Promise<any> => {
  const { error: activeSchoolYearError, data: activeSchoolYearData } = await fetchActiveSchoolYear()

  if (activeSchoolYearError !== null && activeSchoolYearError !== undefined) {
    return { error: 'yes', data: null }
  }

  if (activeSchoolYearData.length === 0 || activeSchoolYearData.length > 1) {
    return { error: 'yes', data: null }
  }

  const schoolYearId = activeSchoolYearData[0].id

  const { error: classesError, data: classesData } = await supabase
    .from('classes')
    .select('*, lesson:lessons(id, name, departement:departements(id,name)), students(*, partition:partitions(*))')
    .eq('schoolyear_id', schoolYearId)

  if (classesError !== null && classesError !== undefined) {
    return { error: 'yes', data: null }
  }

  return { error: null, data: classesData ?? [] }
}

export const fetchClasse = async (id: number): Promise<any> => {
  const { error: activeSchoolYearError, data: activeSchoolYearData } = await fetchActiveSchoolYear()
  let schoolYearId: any = null

  if (!(activeSchoolYearError !== null && activeSchoolYearError !== undefined) &&
  !(activeSchoolYearData.length === 0 || activeSchoolYearData.length > 1)) {
    schoolYearId = activeSchoolYearData[0].id
  }

  return await supabase
    .from('classes')
    .select(`
      *, lesson:lessons(id, name, departement:departements(id,name) ), students(*, partition:partitions(*)),
      subscriptions:student_subscriptions(*,student:students(*,partition:partitions(*)),classe:classes(id, name, schoolyear_id, lesson:lessons(id, name, departement:departements(id,name))))
    `)
    .eq('id', id)
    .filter('subscriptions.classe.schoolyear_id', 'eq', schoolYearId)
    .not('subscriptions.classe', 'is', null)
}

export const createNewClass = async ({ name, lessonId, schoolyearId }: any): Promise<any> => {
  return await supabase
    .from('classes')
    .insert({ name, lesson_id: lessonId, schoolyear_id: schoolyearId })
    .select('*, lesson:lessons(name), students(id)')
}

export const updateClasse = async (classId: number, data: any): Promise<any> => {
  return await supabase
    .from('classes')
    .update(data)
    .eq('id', classId)
    .select('*, lesson:lessons(id, name, departement:departements(id,name) ), students(*, partition:partitions(*))')
}

export const fetchStudents = async (): Promise<any> => {
  const { error: activeSchoolYearError, data: activeSchoolYearData } = await fetchActiveSchoolYear()
  let schoolYearId: any = null

  if (!(activeSchoolYearError !== null && activeSchoolYearError !== undefined) &&
  !(activeSchoolYearData.length === 0 || activeSchoolYearData.length > 1)) {
    schoolYearId = activeSchoolYearData[0].id
  }

  return await supabase
    .from('students')
    .select(`
      *,
      partition:partitions(*),
      subscriptions:student_subscriptions(*,classe:classes(id, name, schoolyear_id, lesson:lessons(id, name, departement:departements(id,name) )))
    `)
    .filter('subscriptions.classe.schoolyear_id', 'eq', schoolYearId)
    .not('subscriptions.classe', 'is', null)
}
export const fetchStudent = async (studentId: number): Promise<any> => {
  const { error: activeSchoolYearError, data: activeSchoolYearData } = await fetchActiveSchoolYear()
  let schoolYearId: any = null

  if (!(activeSchoolYearError !== null && activeSchoolYearError !== undefined) &&
  !(activeSchoolYearData.length === 0 || activeSchoolYearData.length > 1)) {
    schoolYearId = activeSchoolYearData[0].id
  }

  return await supabase
    .from('students')
    .select(`
      *,
      partition:partitions(*),
      subscriptions:student_subscriptions(*,classe:classes(id, name, schoolyear_id, lesson:lessons(id, name, departement:departements(id,name))))
    `)
    .eq('id', studentId)
    .filter('subscriptions.classe.schoolyear_id', 'eq', schoolYearId)
    .not('subscriptions.classe', 'is', null)
}

export const deleteStudentFromClass = async (studentId: number, classeId: number): Promise<any> => {
  return await supabase
    .from('student_subscriptions')
    .delete()
    .eq('student_id', studentId)
    .eq('classe_id', classeId)
    .select('*')
}
export const affectStudentToClass = async (studentId: number, classeId: number): Promise<any> => {
  return await supabase
    .from('student_subscriptions')
    .insert({ student_id: studentId, classe_id: classeId })
    .select('*,classe:classes(id, name, schoolyear_id, lesson:lessons(id, name, departement:departements(id,name)))')
}

export const updateStudent = async (studentId: number, data: any): Promise<any> => {
  return await supabase
    .from('students')
    .update(data)
    .eq('id', studentId)
    .select(`
      *,
      classe:classes(id, name, lesson:lessons(id, name, departement:departements(id,name) )),
      partition:partitions(*)  
    `)
}

export const fetchLesson = async (id: number): Promise<any> => {
  return await supabase
    .from('lessons')
    .select('*, department:departements(id, name), classes:classes(*, students:students(id))')
    .eq('id', id)
}
export const updateLesson = async (id: number, data: any): Promise<any> => {
  return await supabase
    .from('lessons')
    .update(data)
    .eq('id', id)
    .select('*, department:departements(id, name), classes:classes(*, students:students(id))')
}

export const fetchLessons = async (): Promise<any> => {
  return await supabase
    .from('lessons')
    .select('*, department:departements(id, name), classes:classes(id)')
}

export const createNewLesson = async (data: any): Promise<any> => {
  return await supabase
    .from('lessons')
    .insert(data)
    .select('*, department:departements(id, name), classes:classes(id)')
}

export const createNewStudent = async ({ firstname, lastname }: any): Promise<any> => {
  return await supabase
    .from('students')
    .insert({ firstname, lastname })
    .select('*, classe:classes(id, name)')
}

export const uploadPartition = async ({ file }: any): Promise<any> => {
  return await supabase.storage
    .from('partitions')
    .upload(`images/partition-${(new Date()).getTime()}.png`, file)
}

export const uploadPartitionAudio = async ({ file }: any): Promise<any> => {
  return await supabase.storage
    .from('partitions')
    .upload(`audios/partition-${(new Date()).getTime()}.mp3`, file)
}

export const downloadPartition = async ({ filePath }: any): Promise<any> => {
  return await supabase.storage
    .from('partitions')
    .download(filePath)
}

export const createPartition = async ({ name, syllabes, filePath, filePathAudio }: any): Promise<any> => {
  return await supabase
    .from('partitions')
    .insert({
      name,
      syllabes: syllabes.length > 0 ? syllabes : null,
      file_path: filePath,
      file_audio_path: filePathAudio
    })
    .select('*')
}

export const fetchPartitions = async (): Promise<any> => {
  return await supabase
    .from('partitions')
    .select('*, students:students(*,classe:classes(id, name, lesson:lessons(id, name, departement:departements(id,name) )))')
}

export const fetchPartitionById = async (partitionId: number): Promise<any> => {
  return await supabase
    .from('partitions')
    .select('*, students:students(*,classe:classes(id, name, lesson:lessons(id, name, departement:departements(id,name) )))')
    .eq('id', partitionId)
}

export const deletePartitionById = async (partitionId: number): Promise<any> => {
  return await supabase
    .from('partitions')
    .delete()
    .eq('id', partitionId)
}

export const updatePartition = async (partitionId: number, data: any): Promise<any> => {
  return await supabase
    .from('partitions')
    .update(data)
    .eq('id', partitionId)
    .select('*, students:students(*,classe:classes(id, name, lesson:lessons(id, name, departement:departements(id,name) )))')
}

export const fetchPartitionFilePublicURL = async (filePath: string): Promise<any> => {
  return supabase.storage
    .from('partitions')
    .getPublicUrl(filePath)
}

export const deletePartitionFiles = async (filePaths: string[]): Promise<any> => {
  return await supabase.storage
    .from('partitions')
    .remove(filePaths)
}

export const fetchTeacherClassesAccess = async (teacherId: UUID): Promise<any> => {
  return await supabase
    .from('teacher_classes_access')
    .select('*, classe:classes(*, schoolyear:schoolyears(*), lesson:lessons(*, departement:departements(*)), students:students(*))')
    .eq('teacher_id', teacherId)
}

export const createNewTeacherClassesAccess = async (teacherId: UUID, classeId: number): Promise<any> => {
  return await supabase
    .from('teacher_classes_access')
    .insert({ teacher_id: teacherId, classe_id: classeId })
    .select('*, classe:classes(*, lesson:lessons(*, departement:departements(*)), students:students(*))')
}

export const removeTeacherClassesAccess = async (permissionId: number): Promise<any> => {
  return await supabase
    .from('teacher_classes_access')
    .delete()
    .eq('id', permissionId)
}

export const removeDepartement = async (id: number): Promise<any> => {
  return await supabase
    .from('departements')
    .delete()
    .eq('id', id)
}

export const removeLesson = async (id: number): Promise<any> => {
  return await supabase
    .from('lessons')
    .delete()
    .eq('id', id)
}

export const removeClasse = async (id: number): Promise<any> => {
  return await supabase
    .from('classes')
    .delete()
    .eq('id', id)
}

export const removeStudent = async (id: number): Promise<any> => {
  return await supabase
    .from('students')
    .delete()
    .eq('id', id)
}

export const fetchSchoolYears = async (): Promise<any> => {
  return await supabase
    .from('schoolyears')
    .select('*')
}

export const createNewSchoolYear = async (name: string): Promise<any> => {
  return await supabase
    .from('schoolyears')
    .insert({ name, is_active: false })
    .select('*')
}

export const updateSchoolYear = async (id: number, data: any): Promise<any> => {
  return await supabase
    .from('schoolyears')
    .update(data)
    .eq('id', id)
    .select('*')
}

export const disableSchoolYear = async (): Promise<any> => {
  return await supabase
    .from('schoolyears')
    .update({ is_active: false })
    .gt('id', -1)
}

export const fetchActiveSchoolYear = async (): Promise<any> => {
  return await supabase
    .from('schoolyears')
    .select('*')
    .eq('is_active', true)
}
