import Header from '../components/Header/Header'
import Title from '../components/Title/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faBars } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '../context/UserContext'
import Notifications from '../components/Notifications/Notifications'
import ConfirmDialog from '../components/PopUp/ConfirmDialog'
import { useSchoolYear } from '../context/SchoolYearContext'

export function PrivateLayout ({ children, menuName }: any): JSX.Element {
  const [menuVisible, setMenuVisible] = useState(false)
  const [canGoBack, setCanGoBack] = useState(false)
  const localtion = useLocation()
  const navigate = useNavigate()
  const user = useUser()
  const { activeSchoolYear } = useSchoolYear()

  useEffect(() => {
    const pathPattern = /^(\/$|\/[a-zA-Z0-9]+)$/
    setCanGoBack(!pathPattern.test(localtion.pathname))
  }, [localtion.pathname])

  const handletoggleMenu = (): void => {
    setMenuVisible(!menuVisible)
  }

  const closeSlideOnMobile = (): void => {
    if (menuVisible) {
      setMenuVisible(false)
    }
  }

  const handleGoBack = (): void => {
    navigate(-1)
  }

  return (
        <div className='flex'>
          <FontAwesomeIcon icon={faBars} onClick={handletoggleMenu}
          className='fixed top-2 right-2 w-7 h-7 text-gray-500 lg:hidden xl:hidden 2xl:hidden' />
            <Header user={user} selectedMenu={menuName} menuVisible={menuVisible} closeSlideOnMobile={closeSlideOnMobile}/>
            <div className='p-4 lg:ml-[20%] xl:ml-[20%] 2xl:ml-[20%] w-full lg:w-4/5 xl:w-4/5 2xl:w-4/5'>
              <Title>{canGoBack && <FontAwesomeIcon icon={faArrowCircleLeft}
              onClick={handleGoBack}
              className='w-5 h-5 mr-2 cursor-pointer' />}
              {menuName}</Title>
              <div className='mt-4 w-full'>{children}</div>
            </div>
        <Notifications />
        <ConfirmDialog />
        {(activeSchoolYear === null || activeSchoolYear === 'meany' || activeSchoolYear === 'error') &&
         <div className='z-50 fixed bg-red-500 text-center py-4 w-full bottom-0 left-0' style={{ backgroundColor: 'rgb(255,0,0, 0.9)' }}>
          <p className='uppercase text-xs text-white'>
            {activeSchoolYear === null && <span>Vous n&apos;avez marqué aucune année scolaire comme&nbsp;</span>}
            {activeSchoolYear === 'meany' && <span>Vous avez défini plusieurs années scolaires comme&nbsp;</span>}
            {activeSchoolYear === 'error' && <span>Une erreur s&apos;est produite lors de la récupération de l&apos;&nbsp;</span>}
          <Link className='font-bold underline' to={'/school-year'}>année courante</Link>.
          Le sytème ne peut pas fonctionner correctement.</p>
        </div>}
      </div>
  )
}
