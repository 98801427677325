import { Navigate, Outlet } from 'react-router-dom'
import { userIsAuthenticatedSupabase, type AuthState } from '../services/authService'
import SplashScreen from '../components/Loading/SplashScreen'
import { UserProvider } from '../context/UserContext'
import { SchoolYearProvider } from '../context/SchoolYearContext'

export function AuthMiddleware (): JSX.Element {
  const authState: AuthState = userIsAuthenticatedSupabase()

  switch (authState) {
    case 'checking':
      return <SplashScreen />
    case 'unauthenticated':
      return <Navigate to='/login' />
    case 'authenticated':
      return (
        <UserProvider>
          <SchoolYearProvider>
          <Outlet />
          </SchoolYearProvider>
        </UserProvider>
      )
    default:
      return <Navigate to='/login' />
  }
}
