/* eslint-disable @typescript-eslint/no-unused-vars */
import { faArrowDown, faArrowRight, faBook, faBuildingUser, faChalkboardUser, faHeartMusicCameraBolt, faInfoCircle, faMusic, faPlayCircle, faShield, faTrash, faUser, faUsersRectangle, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubTitle from '../components/Title/Subtitle'
import Button from '../components/Button/Button'
import InputText from '../components/InputText/InputText'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from '../components/Dropdown/Dropdown'
import FetchDataError from '../components/Error/FetchDataError'
import SplashComponent from '../components/Loading/SplashComponent'
import Page404Error from '../components/Error/Page404Error'

export default function DepartementScreen ({
  departement,
  handleupdateDepartment,
  updateStatus,
  handleDeleteDepartement
}: any): JSX.Element {
  useEffect(() => {
  }, [])

  if (departement === 'pending') {
    return <SplashComponent />
  } else if (departement === 'error') {
    return <FetchDataError text={departement} />
  } else if (departement === 'notfound') {
    return <Page404Error />
  } else {
    return (
        <div>
            <div></div>
            <div className='w-full mt-6 grid grid-cols-3 gap-2'>
                <div className="col-span-3 lg:col-span-1 xl:col-span-1 2xl:col-span-1">
                    <div className="w-full shadow-lg rounded-lg p-4 py-14">
                        <div className="text-center">
                            <div><FontAwesomeIcon
                            className="rounded-full bg-firstColor text-white p-5 w-14 h-14"
                            icon={faUser} /></div>
                            <p className='font-bold text-xl uppercase tracking-wide'>{departement.name}</p>
                            <p className='text-sm text-gray-500'>
                            </p>
                        </div>
                        <div className='px-4 mt-3 text-xs text-gray-500'>
                            <p>
                            <FontAwesomeIcon icon={faBook} className='mr-1 text-firstColor' />
                            {departement.lessons?.length} cours.
                            </p>
                            <p>
                            <FontAwesomeIcon icon={faBook} className='mr-1 text-firstColor' />
                            {departement.lessons?.reduce((acc: any, lesson: any) => {
                              return acc + lesson?.classes?.reduce((accClasse: any, classe: any) => {
                                return accClasse + (classe.students?.length ?? 0)
                              }, 0)
                            }, 0)} élèves.
                            </p>
                        </div>

                    </div>
                </div>
                <div className="col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <div className='w-full mb-10'>
                        <SubTitle>Informations de base</SubTitle>
                        <form onSubmit={handleupdateDepartment}>
                            <div className='grid grid-cols-2 gap-2'>
                                <div className='col-span-1'><InputText name='name' placeholder='Nom' value={departement.name} /></div>
                                <div className='col-span-1'><Button title='Mettre à jour' isLoading={updateStatus === 'pending'} /></div>
                            </div>
                        </form>
                        <div className='w-full h-'></div>
                        <SubTitle>Classes</SubTitle>
                        <div>
                        <div className='w-full overflow-x-auto mt-6'>
                            <table>
                                <thead>
                                    <tr>
                                    <th>Nom du cours</th>
                                    <th>Nombre de classes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {departement.lessons?.map((lesson: any) => (
                                        <tr key={lesson?.id}>
                                        <td>{lesson?.name ?? 'N/A'}</td>
                                        <td>{lesson?.classes?.length ?? 'N/A'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <div className='w-full h-4'></div>
                    </div>
                </div>
            </div>

        </div>
    )
  }
}
