import { useEffect, useState } from 'react'
import DepartementsScreen from '../screens/departements.screen'
import { createNewDepartment, fetchDepartments } from '../services/adminService'

export default function Departements (): JSX.Element {
  const [departements, setDepartements] = useState<[] | string>('loading')
  const [newEltState, setNewEltState] = useState<null | 'pending' | 'success' | 'error'>(null)

  const setUpDepartments = (): void => {
    fetchDepartments().then(res => {
      if (res.error === null) {
        setDepartements(res.data)
      } else {
        setDepartements('error')
      }
    }).catch(() => {
      setDepartements('error')
    })
  }

  useEffect(() => {
    setUpDepartments()
  }, [])

  const handleCreateNewDepartment = async (event: any): Promise<any> => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)
    if ((formData.get('name') as string).length === 0) {
      return null
    }
    setNewEltState('pending')
    createNewDepartment(data).then(res => {
      if (res.error === null) {
        setUpDepartments()
        setNewEltState('success')
        event.target.reset()
      } else {
        setNewEltState('error')
      }
      event.target.reset()
    }).catch(() => {
      setNewEltState('error')
      event.target.reset()
    })
  }

  return (
        <DepartementsScreen departements={departements}
        handleCreateNewDepartment={handleCreateNewDepartment} newEltState={newEltState} />
  )
}
