import { faArrowRight, faFilter, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import ActionInfo from '../components/ActionInfo/ActionInfo'
import Button from '../components/Button/Button'
import InputText from '../components/InputText/InputText'
import SplashComponent from '../components/Loading/SplashComponent'
import FetchDataError from '../components/Error/FetchDataError'
import Dropdown from '../components/Dropdown/Dropdown'

export default function LessonsScreen ({
  lessons,
  departements,
  handleCreateLesson,
  creatingStatus,
  updateFilters,
  filters,
  filterLessons
}: any): JSX.Element {
  return (
        <div>
            <div className='grid grid-cols-3 p-4 mb-3 shadow-xl rounded-xl'>
                <ActionInfo info='Créer un nouveau cours'
                icon={faPlus} />
                <div className='col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2'>
                    <form onSubmit={handleCreateLesson}>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='col-span-1'><InputText placeholder='Nom' name='name' /></div>
                        <div className='col-span-1'>
                            <Dropdown options={departements.map((departement: any) => (
                              { label: departement.name, value: departement.id }
                            ))} placeholder='Cours' name='departement_id'/>
                        </div>
                        <div className='col-span-1'><Button title='Créer' isLoading={creatingStatus === 'pending'}/>
                        {creatingStatus === 'error' &&
                            <p className='py-2 text-xs text-red-500'>Erreur lors de la création de l&apos;élève.</p>}
                        </div>
                    </div>
                    </form>
                </div>
            </div>

            <div className='bg-white shadow-xs rounded-lg p-4'>
                <p className='uppercase text-xs text-firstColor'><FontAwesomeIcon icon={faFilter} className='mr-1' />filtrer les cours</p>
                <form onSubmit={(e: any) => { e.preventDefault(); e.target.reset(); updateFilters({ name: '', departement: null }) }}>
                <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4 mt-2'>
                    <div className='col-span-1'>
                        <InputText placeholder='Nom' onInput={(e: any) => {
                          updateFilters((prevFilters: any) => ({ ...prevFilters, name: e.target.value }))
                        }}/>
                    </div>
                    <div className='col-span-1'>
                        <Dropdown options={departements.map((departement: any) => (
                          { label: departement.name, value: departement.id }
                        ))} placeholder='Cours' name='departement'
                            onChange={(e: any) => {
                              updateFilters((prevFilters: any) =>
                                ({ ...prevFilters, departement: Number(e.target.value) }))
                            }} />
                    </div>
                </div>
                {(filters.name.length > 0 || filters.departement !== null) && <Button icon={faXmark} title='Réinitialiser' className='float-right my-3' />}
                </form>
            </div>

            <div className='w-full overflow-x-auto mt-6'>
                <table>
                    <thead>
                        <tr>
                        <th>Nom</th>
                        <th>Département</th>
                        <th>Nombre de classes</th>
                        <th>Voir la fiche</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!['loading', 'error'].includes(lessons) && filterLessons().map((lesson: any) => (
                            <tr key={lesson.id}>
                            <td>{lesson.name}</td>
                            <td>{lesson.department?.name ?? 'N/A'}</td>
                            <td>{lesson.classes?.length ?? 'N/A'}</td>
                            <td><Link to={`/lessons/${lesson.id}`}><FontAwesomeIcon icon={faArrowRight} className='text-firstColor' /></Link></td>
                        </tr>
                        ))}

                    </tbody>
                </table>
                <SplashComponent cond={lessons === 'loading'} />
                <FetchDataError cond={lessons === 'error'} />
            </div>

        </div>
  )
}
