import { Navigate } from 'react-router-dom'
import LoginScreen from '../screens/login.screen'
import { useState } from 'react'
import { type AuthState, userIsAuthenticatedSupabase } from '../services/authService'
import { createClient } from '@supabase/supabase-js'
import SplashScreen from '../components/Loading/SplashScreen'
import { signInWIthPassword } from '../services/browserService'

export default function Login (): JSX.Element {
  const supabase = createClient('https://kctgvhrqmfvrfdmklepi.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtjdGd2aHJxbWZ2cmZkbWtsZXBpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTg3NzIwMzEsImV4cCI6MjAxNDM0ODAzMX0.rNP7S10Za79nm70JzBjpW-B9GFuZ2a9D1qlC3tGCpvk')

  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(null)
  const authState: AuthState = userIsAuthenticatedSupabase()
  const [buttonIsLoading, setButtonIslOading] = useState(false)

  const login = async (email: string, password: string): Promise<void> => {
    await signInWithEmail(email, password)
  }

  const submitLoginForm = (event: any): void => {
    event.preventDefault()
    setButtonIslOading(true)
    setLoginErrorMessage(null)
    const formData = new FormData(event.target)
    const email: string = formData.get('email') as string
    const password: string = formData.get('password') as string
    if (email.length > 0 && password.length > 0) {
      login(email, password).then().catch(() => {})
    } else {
      setLoginErrorMessage('Adresse e-mail ou mot de passe invalide.')
      setButtonIslOading(false)
    }
  }

  async function signInWithEmail (email: string, password: string): Promise<void> {
    const { error } = await signInWIthPassword(email, password)
    setButtonIslOading(false)
    if (error !== null) {
      setLoginErrorMessage('Adresse e-mail ou mot de passe invalide.')
    }
  }

  switch (authState) {
    case 'checking': return <SplashScreen />
    case 'authenticated': return <Navigate to='/' />
    case 'unauthenticated': return (
      <LoginScreen submitLoginForm={submitLoginForm}
      loginErrorMessage={loginErrorMessage} supabase={supabase} buttonIsLoading={buttonIsLoading} />
    )
  }
}
