import { type ReactNode, createContext, useContext, useState, useEffect } from 'react'
import { fetchActiveSchoolYear } from '../services/adminService'

const SchoolYearContext = createContext<any>(undefined)

interface SchoolYearProviderProps {
  children: ReactNode
}
export function SchoolYearProvider ({ children }: SchoolYearProviderProps): JSX.Element {
  const [activeSchoolYear, setActiveSchoolYear] = useState<any | 'error' | 'pending' | 'meany' | null>('pending')

  useEffect(() => {
    const setActiveYearOnSystem = async (): Promise<any> => {
      const { error, data } = await fetchActiveSchoolYear()
      if (error === undefined || error === null) {
        if (data.length === 1) {
          setActiveSchoolYear(data[0])
        } else if (data.length > 1) {
          setActiveSchoolYear('meany')
        } else {
          setActiveSchoolYear(null)
        }
      } else {
        setActiveSchoolYear('error')
      }
    }

    void setActiveYearOnSystem()
  }, [])

  const updateActiveSchoolYear = async (): Promise<any> => {
    try {
      const { error, data } = await fetchActiveSchoolYear()
      if (error === undefined || error === null) {
        if (data.length === 1) {
          setActiveSchoolYear(data[0])
        } else if (data.length > 1) {
          setActiveSchoolYear('meany')
        } else {
          setActiveSchoolYear(null)
        }
      } else {
        setActiveSchoolYear('error')
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'année scolaire active :', error)
    }
  }
  return <SchoolYearContext.Provider value={{ activeSchoolYear, updateActiveSchoolYear }}>
        {children}
    </SchoolYearContext.Provider>
}

export function useSchoolYear (): any {
  const context = useContext(SchoolYearContext)

  if (context === undefined) {
    throw new Error('useSchoolYear must be used within a SchoolYearProvider')
  }

  return context
}
