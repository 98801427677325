import { faArrowRight, faFileDownload, faFilter, faMagnifyingGlass, faUsersRectangle } from '@fortawesome/free-solid-svg-icons'
import ActionInfo from '../components/ActionInfo/ActionInfo'
import Button from '../components/Button/Button'
import InputText from '../components/InputText/InputText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UploadFile from '../components/UploadFile/UploadFile'
import InputNumber from '../components/InputNumber/InputNumber'
import FormValidationError from '../components/Error/FormValidationError'
import SplashComponent from '../components/Loading/SplashComponent'
import FetchDataError from '../components/Error/FetchDataError'
import { Link } from 'react-router-dom'

export default function PartitionsScreen ({
  partitions,
  handleCreateNewPartition,
  newEltState,
  handleDownloadPartitionFile,
  updateFilters,
  filterPartitions
}: any): JSX.Element {
  return (
    <div>
            <div className='grid grid-cols-3 p-4 mb-3 shadow-xl rounded-xl'>
                <ActionInfo info='Filtrer la liste des partitions'
                icon={faMagnifyingGlass} />
                <div className='col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2'>
                    <form onSubmit={handleCreateNewPartition}>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'>
                        <p className='text-sm'>Nom<span className='text-red-500'>*</span></p>
                        <InputText placeholder='Nom' name='name' />
                        </div>
                        <div className='col-span-1'>
                        <p className='text-sm'>Syllabes</p>
                        <InputNumber min={0} placeholder='Nombre de syllabes' name='syllabes' />
                        </div>
                        <div className='col-span-1'>
                            <p className='text-sm'>Image de la partition<span className='text-red-500'>*</span></p>
                            <UploadFile name='file' label='Charger un fichier' accept='.png,.jpeg,.jpg' />
                        </div>
                        <div className='col-span-1'>
                        <p className='text-sm'>Audio de la partition<span className='text-red-500'>*</span></p>
                            <UploadFile name='fileAudio' label='Charger un fichier' accept='audio/*' />
                        </div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'>
                            <Button title='Ajouter' loadingText='Traitement ...'
                            isLoading={newEltState === 'pending'} />
                            {newEltState !== null && newEltState !== 'success' && newEltState !== 'pending' &&
                            <FormValidationError text={newEltState} /> }
                        </div>
                    </div>
                    </form>
                </div>
            </div>

            <div className='bg-white shadow-xs rounded-lg p-4'>
                <p className='uppercase text-xs text-firstColor'><FontAwesomeIcon icon={faFilter} className='mr-1' />filtrer les partitions</p>
                <div className='grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4 mt-2'>
                    <div className='col-span-1'>
                        <InputText placeholder='Nom' onInput={(e: any) => {
                          updateFilters((prevFilters: any) => ({ ...prevFilters, name: e.target.value }))
                        }}/>
                    </div>
                    <div className='col-span-1'>
                        <InputNumber placeholder='Nombre de syllabes' onInput={(e: any) => {
                          updateFilters((prevFilters: any) => ({ ...prevFilters, syllabes: Number(e.target.value) }))
                        }} />
                    </div>
                    <div className='col-span-1'>
                        <InputNumber placeholder='Nombre d&apos;élèves' onInput={(e: any) => {
                          updateFilters((prevFilters: any) => ({ ...prevFilters, students: Number(e.target.value) }))
                        }} />
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 mt-4 py-4 px-2 gap-2'>
                {partitions !== 'pending' && partitions !== 'error' &&
                filterPartitions().map((partition: any) => (
                    <div className='col-span-1' key={partition.id}>
                        <div className='shadow-lg rounded-md p-4 pb-8' style={{ minHeight: '250px' }}>
                            <div>
                                <img src={partition.url} className='w-full' />
                            </div>
                            <div>
                                <div className='text-center'>
                                    <FontAwesomeIcon icon={faFileDownload}
                                    className='w-6 h-6 cursor-pointer text-firstColor mt-2'
                                    title="Télécharger l'image"
                                    onClick={() => { handleDownloadPartitionFile(partition.file_path) }} />
                                    <audio controls className='w-full my-2' src={partition.audioFileUrl}></audio>
                                </div>
                            </div>
                            <div className='-mb-4'>
                                <p className='text-sm'>{partition.name}</p>
                                <p className='text-xs mt-2'>
                                    <FontAwesomeIcon icon={faUsersRectangle} className='mr-1 text-firstColor' />
                                    {partition.students?.length} élève{partition.students?.length > 1 ? 's' : ''}
                                    <Link to={`/partitions/${partition.id}`}>
                                        <FontAwesomeIcon icon={faArrowRight} className='float-right text-lg' />
                                    </Link>
                                </p>
                            </div>
                        </div>
                </div>
                ))}
            </div>
            <SplashComponent cond={partitions === 'pending'}/>
            <FetchDataError cond={partitions === 'error'} />
        </div>
  )
}
