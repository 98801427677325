import { Navigate, Route } from 'react-router-dom'
import { PrivateLayout } from '../layout/PrivateLayout'
import Dashboard from '../containers/dashboard'
import Users from '../containers/users'
import Departements from '../containers/departements'
import Classes from '../containers/classes'
import Students from '../containers/students'
import User from '../containers/user'
import Partitions from '../containers/paritions'
import Lessons from '../containers/lessons'
import Student from '../containers/student'
import Classe from '../containers/classe'
import Lesson from '../containers/lesson'
import Departement from '../containers/departement'
import AdminRoleMiddleware from '../middlewares/admin.role.middleware'
import Partition from '../containers/partition'
import SchoolYear from '../containers/SchoolYear'

export const privateRoutes = <>
    <Route path='/' element={<Navigate to='/dashboard'/>}/>
    <Route path='dashboard' element={<PrivateLayout menuName='Accueil'><Dashboard /></PrivateLayout>}></Route>
    <Route path='users' element={<AdminRoleMiddleware><PrivateLayout menuName='Utilisateurs'><Users /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='school-year' element={<AdminRoleMiddleware><PrivateLayout menuName='Année scolaire'><SchoolYear /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='users/:userId' element={<AdminRoleMiddleware><PrivateLayout menuName='Utilisateurs'><User /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='departments' element={<AdminRoleMiddleware><PrivateLayout menuName='Départements'><Departements /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='departments/:departementId' element={<AdminRoleMiddleware><PrivateLayout menuName='Départements'><Departement /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='lessons' element={<AdminRoleMiddleware><PrivateLayout menuName='Cours'><Lessons /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='lessons/:lessonId' element={<AdminRoleMiddleware><PrivateLayout menuName='Cours'><Lesson /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='classes' element={<AdminRoleMiddleware><PrivateLayout menuName='Classes'><Classes /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='classes/:classeId' element={<PrivateLayout menuName='Classes'><Classe /></PrivateLayout>}></Route>
    <Route path='students' element={<AdminRoleMiddleware><PrivateLayout menuName='Élèves'><Students /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='students/:studentId' element={<AdminRoleMiddleware><PrivateLayout menuName='Élèves'><Student /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='partitions' element={<AdminRoleMiddleware><PrivateLayout menuName='Partitions'><Partitions /></PrivateLayout></AdminRoleMiddleware>}></Route>
    <Route path='partitions/:partitionId' element={<AdminRoleMiddleware><PrivateLayout menuName='Partitions'><Partition /></PrivateLayout></AdminRoleMiddleware>}></Route>
</>
