import { useEffect, useRef, useState } from 'react'
import Button from '../Button/Button'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

interface FileUploadProps {
  label: string | undefined
  name?: string
  value?: string
  errorMessage?: any
  accept?: string
}
export default function UploadFile ({ label, name, value, errorMessage, accept }: FileUploadProps): JSX.Element {
  const fileSelectRef = useRef<HTMLInputElement | null>(null)
  const [fileName, setFileName] = useState<string | null>(null)
  const MAX_NAME_LENGTH_TO_DISPLAY = 12

  useEffect(() => {
    if (fileSelectRef.current?.value.length === 0) setFileName(null)
  }, [fileSelectRef.current?.value])

  const handleFileSelect = (): void => {
    if (fileSelectRef.current !== null) {
      fileSelectRef.current.click()
    }
  }

  const handleOnFileChange = (event: any): void => {
    if (event.target.files.length > 0) {
      const value = event.target.files[0].name

      let filename: any = value.split('.')
      if (filename.length > 0 && filename[0].length > MAX_NAME_LENGTH_TO_DISPLAY) {
        filename = filename[0].slice(0, MAX_NAME_LENGTH_TO_DISPLAY) + '... .' + filename[1]
      } else {
        filename = value
      }

      setFileName(filename)
    }
  }

  return (
      <>
        <input type='file' placeholder={label} name={name} id='file-upload-1'
        className="hidden"
        ref={fileSelectRef}
        defaultValue={value}
        onChange={handleOnFileChange}
        accept={accept}
        />
        <div className='mt-1'>
        <span className='text-sm mr-2 text-gray-500 block mb-2
        md:inline md:mb-0 lg:inline lg:mb-0 xl:inline xl:mb-0 2xl:inline 2xl:mb-0'>
            {fileName ?? 'Charger un fichier'}</span>
        <Button asLabel title='' className='bg-secondColor text-black text-bold cursor-pointer'
        icon={faPlus} onClick={handleFileSelect}></Button>
        </div>

        {errorMessage !== null && <p className="text-xs pt-1 text-red-500">{errorMessage}</p>}
      </>
  )
}
