import { faBuildingUser, faUser, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubTitle from '../components/Title/Subtitle'
import Button from '../components/Button/Button'
import InputText from '../components/InputText/InputText'
import { useEffect } from 'react'
import Dropdown from '../components/Dropdown/Dropdown'
import SplashComponent from '../components/Loading/SplashComponent'
import Page404Error from '../components/Error/Page404Error'

export default function LessonScreen ({
  lesson,
  departments,
  handleUpdateLesson,
  updateStatus,
  handleDeleteLesson
}: any): JSX.Element {
  useEffect(() => {
  }, [])

  if (lesson === 'pending') {
    return <SplashComponent />
  } else if (lesson === 'error') {
    <Page404Error />
  } else if (lesson === 'notfound') {
    return <Page404Error />
  }

  return (
        <div>
            <div></div>
            <div className='w-full mt-6 grid grid-cols-3 gap-2'>
                <div className="col-span-3 lg:col-span-1 xl:col-span-1 2xl:col-span-1">
                    <div className="w-full shadow-lg rounded-lg p-4 py-14">
                        <div className="text-center">
                            <div><FontAwesomeIcon
                            className="rounded-full bg-firstColor text-white p-5 w-14 h-14"
                            icon={faUser} /></div>
                            <p className='font-bold text-xl uppercase tracking-wide'>{lesson.name}</p>
                            <p className='text-sm text-gray-500'>
                            </p>
                        </div>
                        <div className='px-4 mt-3 text-xs text-gray-500'>
                            <FontAwesomeIcon icon={faBuildingUser} className='mr-1 text-firstColor' />
                            {lesson.department?.name}
                        </div>
                        <div className='mt-6 text-center'>
                            <Button icon={faXmark} title='Supprimer' onClick={handleDeleteLesson} />
                        </div>
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <div className='w-full mb-10'>
                        <SubTitle>Informations de base</SubTitle>
                        <form onSubmit={handleUpdateLesson}>
                            <div className='grid grid-cols-2 gap-2'>
                                <div className='col-span-1'><InputText name='name' placeholder='Nom' value={lesson.name} /></div>
                                <div className='col-span-1'>
                                    <Dropdown options={departments.map((department: any) => ({
                                      value: department.id, label: department.name
                                    }))} placeholder={'Département'} name='departement_id'/>
                                </div>
                                <div className='col-span-1'></div>
                                <div className='col-span-1'><Button title='Mettre à jour' isLoading={updateStatus === 'pending'} /></div>
                            </div>
                        </form>
                        <div className='w-full h-'></div>
                        <SubTitle>Classes</SubTitle>
                        <div>
                        <div className='w-full overflow-x-auto mt-6'>
                            <table>
                                <thead>
                                    <tr>
                                    <th>Nom de la classe</th>
                                    <th>Nombre d&apos;élèves</th>
                                    <th>Date d&apos;de création</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lesson.classes?.map((theClass: any) => (
                                        <tr key={theClass?.id}>
                                        <td>{theClass?.name ?? 'N/A'}</td>
                                        <td>{theClass?.students?.length}</td>
                                        <td>{(new Date(theClass.created_at)).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <div className='w-full h-4'></div>
                    </div>
                </div>
            </div>

        </div>
  )
}
