/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import StudentScreen from '../screens/student.screen'
import { useLocation, useParams } from 'react-router-dom'
import { affectStudentToClass, deleteStudentFromClass, downloadPartition, fetchClasses, fetchPartitions, fetchStudent, fetchStudents, removeStudent, updateStudent } from '../services/adminService'
import { downloadFileBrowser } from '../services/browserService'
import { useNotificationStore } from '../store/notificationStore'
import useConfirmDialogStore from '../store/confirmDialogStore'
import { useSchoolYear } from '../context/SchoolYearContext'

export default function Student (): JSX.Element {
  const location = useLocation()
  const [student, setStudent] = useState<any>('pending')
  const [partitions, setPartitions] = useState<string | any[]>('pending')
  const [classes, setClasses] = useState<'pending' | 'error' | any[]>('pending')
  const [updatePersonalDataStatus, setUpdatePersonalDataStatus] = useState<string | null>(null)
  const [dissociatePartitionStatus, setDissociatePartitionStatus] = useState<string | null>(null)
  const [associatePartitionStatus, setAssociatePartitionStatus] = useState<string | null>(null)
  const { studentId } = useParams()
  const { addNotification } = useNotificationStore()
  const { openDialog } = useConfirmDialogStore()
  const { activeSchoolYear } = useSchoolYear()
  const [isClasseSetup, setIsclasseSetup] = useState(false)

  const refreshStudent = (): any => {
    setPartitions('pending') // the partitions list will be refreshed as the student will be updated.
    fetchStudent(Number(studentId)).then(async res => {
      if (res.error === null) {
        const subscriptionOnCurrentYear = res.data[0].subscriptions.filter((s: any) => {
          return s?.classe?.schoolyear_id === activeSchoolYear?.id
        })
        const temp = res.data.length > 0
          ? { ...res.data[0], classe: subscriptionOnCurrentYear.length > 0 ? subscriptionOnCurrentYear[0].classe : null }
          : 'notfound'
        setStudent(temp)
      } else {
        setStudent('error')
        addNotification('Page élève introuvable!', 'error')
      }
    }).catch(() => {
      setStudent('error')
      addNotification('Page élève introuvable!', 'error')
    })
  }

  useEffect(() => {
    refreshStudent()

    fetchClasses().then(res => {
      if (res.error === null || res.error === undefined) {
        setClasses(res.data)
      } else {
        setClasses('error')
      }
    }).catch(() => {
      setClasses('error')
    })
  }, [])

  useEffect(() => {
    const setupPartitions = async (): Promise<any> => {
      if (!isClasseSetup) {
        if (student !== 'pending' && student !== 'error') {
          const followedCourseByStudent = student?.subscriptions?.filter((elt: any) => {
            return elt?.classe?.schoolyear_id === activeSchoolYear?.id
          })
          const { data: studentsList } = await fetchStudents()
          fetchPartitions().then(async res => {
            if (res.error === null || res.error === undefined) {
              const partitions_: any[] = []
              for (let i = 0; i < res.data.length; i++) {
                if ((await checkIfAssociationIsPossibleV2(res.data[i].id, studentsList) === true)) {
                  partitions_.push(res.data[i])
                }
              }
              setPartitions(
                partitions_
              )
            } else setPartitions('error')
          }).catch(() => {
            setPartitions('error')
          })
        }
      }
    }
    void setupPartitions()
  }, [student])

  const handleDownloadPartitionFile = (filePath: string): void => {
    downloadPartition({ filePath }).then(res => {
      if (res.error === null) {
        addNotification('Le téléchargement du fichier a commencé', 'infos')
        downloadFileBrowser(res.data, filePath)
      }
    }).catch(() => {
      addNotification('Une erreur s\'est produite lors du téléchargement du fichier', 'error')
    })
  }

  const handleUpdateStudent = (e: any): void => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)
    setUpdatePersonalDataStatus('pending')
    updateStudent(data.studentId as unknown as number, {
      firstname: data.firstname,
      lastname: data.lastname
    }).then(res => {
      setUpdatePersonalDataStatus(null)
      if (res.error === null) {
        refreshStudent()
        addNotification('Informations mises à jour avec succès!', 'success')
      } else {
        addNotification('Impossible de mettre à jour les informations, veuillez réessayer.', 'error')
      }
    }).catch(() => {
      setUpdatePersonalDataStatus(null)
      addNotification('Impossible de mettre à jour les informations, veuillez réessayer.', 'error')
    })
  }

  const handleRemoveDissociatePartition = (): void => {
    setDissociatePartitionStatus('pending')
    updateStudent(student.id, {
      partition_id: null
    }).then(res => {
      setDissociatePartitionStatus(null)
      if (res.error === null) {
        refreshStudent()
        addNotification('La partition a été dissociée avec succès!', 'success')
      } else addNotification('Une erreur s\'est produite lors de la dissociation de la partition', 'error')
    }).catch(() => {
      setDissociatePartitionStatus(null)
      addNotification('Une erreur s\'est produite lors de la dissociation de la partition', 'error')
    })
  }

  const checkIfAssociationIsPossible = async (partitionId: number, departementId: number | null = null): Promise<any> => {
    let b = true
    if (departementId === null) {
      departementId = Number(student.classe?.lesson?.departement?.id)
    }
    await fetchStudents().then(res => {
      if (res.error !== null) setAssociatePartitionStatus('Une erreur s\'est produite lors de l\'association de la partition à l\'élève, veuillez réessayer.')
      else {
        const studentsList = res.data
        for (let i = 0; i < studentsList.length; i++) {
          const studentObj = studentsList[i]
          if (
            studentObj.id !== student.id &&
            studentObj.partition_id !== null &&
            Number(studentObj.partition_id) === Number(partitionId) &&
            studentObj.subscriptions?.length > 0
          ) {
            const subscriptionCurrentYearOfStudentObj = studentObj.subscriptions.filter((elt: any) => {
              return elt.classe?.schoolyear_id === activeSchoolYear?.id
            })
            if (subscriptionCurrentYearOfStudentObj.length > 0) {
              if (subscriptionCurrentYearOfStudentObj[0].classe?.lesson?.departement?.id === departementId) {
                b = false
              }
            }
          }
        }
      }
    }).catch(() => {
      setAssociatePartitionStatus('Une erreur s\'est produite lors de l\'association de la partition à l\'élève, veuillez réessayer.')
    })
    return b
  }

  const checkIfAssociationIsPossibleV2 = async (partitionId: number, studentsList: any = null): Promise<any> => {
    let b: boolean = true
    const followedCourseByStudent = student?.subscriptions?.filter((elt: any) => {
      return elt?.classe?.schoolyear_id === activeSchoolYear?.id
    })
    const courseId = followedCourseByStudent[0]?.classe?.lesson?.id ?? null
    if (courseId === null) {
      return true
    }

    try {
      if (studentsList === null) {
        const { data: studentsList } = await fetchStudents() // firstly, let's get the list of all students
      }
      for (let i = 0; i < studentsList.length; i++) {
        const student_ = studentsList[i]
        if (student_.id !== student?.id) { // skip the current student
          const subscriptions_ = student_?.subscriptions?.filter((elt: any) => { // keep only student's subscriptions of the current school year
            return elt?.classe?.schoolyear_id === activeSchoolYear?.id
          })
          for (let j = 0; j < subscriptions_.length; j++) { // iterate subscriptions of the student of the current school year
            if (subscriptions_[j]?.classe?.lesson?.id === courseId) { // get the courses followed by the student
              if (student_.partition?.id === partitionId) { // now, this student is in the same course with -YOU-, check if they have the same partition as -YOU-
                b = false
              }
            }
          }
        }
      }
    } catch (error) {
      setAssociatePartitionStatus('Une erreur s\'est produite lors de l\'association de la partition à l\'élève, veuillez réessayer.')
    }
    return b
  }

  const handleAssociatePartition = async (e: any): Promise<any> => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)
    setAssociatePartitionStatus('pending')
    if (await checkIfAssociationIsPossibleV2(data.partition_id as unknown as number) === false) {
      addNotification('Cette partition est déjà associé à un élève du même département', 'error')
      setAssociatePartitionStatus(null)
    } else {
      await updateStudent(student.id, {
        partition_id: data.partition_id
      }).then(res => {
        setAssociatePartitionStatus(null)
        if (res.error !== null) {
          setAssociatePartitionStatus('Une erreur s\'est produite lors de l\'association de la partition à l\'élève, veuillez réessayer.')
        } else {
          setStudent(res.data[0])
          addNotification('Partition associée avec succès!', 'success')
        }
      }).catch(() => {
        setAssociatePartitionStatus(null)
        setAssociatePartitionStatus('Une erreur s\'est produite lors de l\'association de la partition à l\'élève, veuillez réessayer.')
      })
    }
  }

  const deleteStudent = async (): Promise<any> => {
    if (student !== 'pending' || student !== 'error') {
      const { error } = await removeStudent(student.id)
      if (error === null || error === undefined) {
        setStudent('notfound')
      } else {
        addNotification("Une erreur s'est produite lors de la suppression de l'élève", 'error')
      }
    }
  }

  const handleDeleteStudent = (): void => {
    openDialog('Cette opération est irréversible.', () => {
      void deleteStudent()
    })
  }

  const handleAffectStudentToAClass = async (event: any): Promise<any> => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)
    try {
      if (student?.classe !== null) {
        const { error } = await deleteStudentFromClass(student?.id, student?.classe?.id)
        if (error !== undefined && error !== null) {
          throw new Error('Une erreur s\'est produite lors de la mise à jour de la classe.')
        }
      }
      if (classes !== 'pending' && classes !== 'error') {
        let dep: any[] | number = classes.filter((classe: any) => {
          return classe.id === Number(data.classe_id)
        })
        if (dep.length > 0) {
          dep = dep[0]?.lesson?.departement?.id
          if (student.partition_id === null || await checkIfAssociationIsPossible(student.partition_id, Number(dep)) === true) {
            const { error, data: resData } = await affectStudentToClass(student?.id, Number(data.classe_id))
            if ((error === null || error === undefined) && resData.length > 0 && resData[0].classe !== null) {
              addNotification('Classe mise à jour avec succès!', 'success')

              setStudent({ ...student, classe: resData[0].classe })
            } else {
              addNotification('Une erreur s\'est produite lors de la mise à jour de la classe.', 'error')
            }
          } else {
            addNotification(`Cet élève ne peut pas être affecté à la classe séléctionnée,
             car sa partition est déjà affectée à un autre élève du même département de
             la classe séléctionnée.`, 'error')
          }
        }
      }
    } catch (error: any) {
      addNotification(error, 'error')
    }
  }

  return (
        <StudentScreen student={student} partitions={partitions}
        handleDownloadPartitionFile={handleDownloadPartitionFile}
        handleUpdateStudent={handleUpdateStudent}
        updatePersonalDataStatus={updatePersonalDataStatus}
        handleRemoveDissociatePartition={handleRemoveDissociatePartition}
        dissociatePartitionStatus={dissociatePartitionStatus}
        handleAssociatePartition={handleAssociatePartition}
        associatePartitionStatus={associatePartitionStatus}
        handleDeleteStudent={handleDeleteStudent}
        checkIfAssociationIsPossible={checkIfAssociationIsPossible}
        classes={classes}
        handleAffectStudentToAClass={handleAffectStudentToAClass}
        activeSchoolYear={activeSchoolYear} />
  )
}
