import cleanGrayPaper from '../assets/images/clean-gray-paper.png'

export function ConnexionLayout ({ children }: any): JSX.Element {
  return (
          <>
              <div className="p-4 h-full w-full absolute"
              style={{
                backgroundImage: `url(${cleanGrayPaper})`

              }}>{children}</div>
          </>
  )
}
