import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { deletePartitionById, deletePartitionFiles, fetchPartitionById, fetchPartitionFilePublicURL, updatePartition, uploadPartition } from '../services/adminService'
import PartitionScreen from '../screens/partition.screen'
import { useNotificationStore } from '../store/notificationStore'
import useConfirmDialogStore from '../store/confirmDialogStore'

export default function Partition (): JSX.Element {
  const acceptedPartitionFileType = ['image/png', 'image/jpeg']
  const acceptedPartitionAudioFileType = ['audio/mpeg']
  const { partitionId } = useParams()
  const [partition, setPartition] = useState<any>('pending')
  const [updateStatus, setUpdateStatus] = useState<null | 'pending' | 'error'>(null)
  const { addNotification } = useNotificationStore()
  const { openDialog } = useConfirmDialogStore()

  const setupPartitionFiles = async (filePath: string, fileAudioPath: string): Promise<any> => {
    return await new Promise((resolve, reject) => {
      Promise.all([
        fetchPartitionFilePublicURL(filePath),
        fetchPartitionFilePublicURL(fileAudioPath)
      ]).then(values => {
        const [imgUrl, audioUrl] = [values[0].data.publicUrl, values[1].data.publicUrl]
        resolve({ imgUrl, audioUrl })
      }).catch(() => {
        reject(new Error('Une erreur s\'est produite lors de la mise à jour des fichiers.'))
      })
    })
  }

  const setupPartition = async (): Promise<any> => {
    const { error, data } = await fetchPartitionById(Number(partitionId))

    if ((error === undefined || error === null) &&
    (data !== null && Array.isArray(data) && data.length > 0)) {
      const { imgUrl, audioUrl } = await setupPartitionFiles(data[0].file_path, data[0].file_audio_path)
      data[0].imgUrl = imgUrl; data[0].audioUrl = audioUrl
      setPartition(data[0])
    } else if ((error === undefined || error === null) &&
    (data !== null && Array.isArray(data) && data.length === 0)) {
      setPartition('notfound')
    } else {
      setPartition('error')
    }
  }

  useEffect(() => { void setupPartition() }, [partitionId])
  const handleUpdatePartition = async (e: any): Promise<any> => {
    e.preventDefault()
    setUpdateStatus('pending')
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)
    if ((data.name as string).length === 0) {
      setUpdateStatus('error')
      addNotification('Le champ "Nom" ne peut pas être vide', 'error')
      return null
    }
    const filesPath = {
      file_path: partition.file_path,
      file_path_audio: partition.file_audio_path
    }

    if (data.file instanceof File && data.file.size > 0 && data.file.name.length > 0) {
      if (!acceptedPartitionFileType.includes(data.file.type)) {
        addNotification('Vous devez choisir une image de type JPEG ou PNG', 'error')
        setUpdateStatus(null)
        return null
      } else {
        const { error: deleteErr } = await deletePartitionFiles([partition.file_path])
        if (deleteErr !== null && deleteErr !== undefined) {
          addNotification('Nous n\'avons pas pu mettre à jour l\'image de la partition.', 'error')
        } else {
          const { error: uploadNewErr, data: uploadNewData } = await uploadPartition(data)
          if (uploadNewErr === null || uploadNewErr === undefined) {
            filesPath.file_path = uploadNewData.path
          } else {
            addNotification('Nous n\'avons pas pu mettre à jour l\'image de la partition.', 'error')
            return null
          }
        }
      }
    }
    if (data.fileAudio instanceof File && data.fileAudio.size > 0 && data.fileAudio.name.length > 0) {
      if (!acceptedPartitionAudioFileType.includes(data.fileAudio.type)) {
        addNotification('Vous devez choisir un fichier audio de type MP3', 'error')
        return null
      } else {
        const { error: deleteErr } = await deletePartitionFiles([partition.file_audio_path])
        if (deleteErr !== null && deleteErr !== undefined) {
          addNotification('Nous n\'avons pas pu mettre à jour le fichier audio de la partition.', 'error')
        } else {
          const { error: uploadNewErr, data: uploadNewData } = await uploadPartition({ file: data.fileAudio })
          if (uploadNewErr === null || uploadNewErr === undefined) {
            filesPath.file_path_audio = uploadNewData.path
          } else {
            addNotification('Nous n\'avons pas pu mettre à jour le fichier audio de la partition.', 'error')
          }
        }
      }
    }

    const { error: updateErr, data: updateData } = await updatePartition(Number(partitionId),
      {
        name: data.name as string,
        syllabes: data.syllabes as unknown as number,
        file_path: filesPath.file_path,
        file_audio_path: filesPath.file_path_audio
      }
    )
    if (updateErr !== null && updateErr !== null) {
      addNotification('Une erreur s\'est produite lors de la mise à jour de la partition', 'error')
      setUpdateStatus('error')
      return null
    } else {
      addNotification('Partition mise à jour avec succès!', 'success')
    }

    const { imgUrl, audioUrl } = await setupPartitionFiles(updateData[0].file_path, updateData[0].file_audio_path)
    updateData[0].imgUrl = imgUrl; updateData[0].audioUrl = audioUrl
    setPartition(updateData[0])

    setUpdateStatus(null)
  }

  const deletePartition = async (): Promise<any> => {
    const { error: deleteFilesErr } = await deletePartitionFiles(
      [
        partition.file_path,
        partition.file_audio_path
      ]
    )
    if (deleteFilesErr !== null && deleteFilesErr !== undefined) {
      addNotification('Une erreur s\'est produite lors de la suppression des fichiers.', 'error')
    } else {
      const { error: deleteErr } = await deletePartitionById(Number(partition.id))
      if (deleteErr !== null && deleteErr !== undefined) {
        addNotification('Une erreur s\'est produite lors de la suppression de la partition', 'error')
      } else {
        addNotification('La partition a été supprimée avec succès!', 'success')
        setPartition('notfound')
      }
    }
  }

  const handleDeletePartition = (): void => {
    openDialog('Cette opération est irréversible.', () => {
      void deletePartition()
    })
  }

  return (
    <PartitionScreen partition={partition} updateStatus={updateStatus}
    handleUpdatePartition={handleUpdatePartition} handleDeletePartition={handleDeletePartition} />
  )
}
