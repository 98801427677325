import { useEffect, useState } from 'react'
import SchoolYearScreen from '../screens/schoolyear.screen'
import { createNewSchoolYear, disableSchoolYear, fetchSchoolYears, updateSchoolYear } from '../services/adminService'
import { useNotificationStore } from '../store/notificationStore'
import { useSchoolYear } from '../context/SchoolYearContext'

export default function SchoolYear (): JSX.Element {
  const [schoolYears, setSchoolYears] = useState<'pending' | 'error' | any[]>('pending')
  const { addNotification } = useNotificationStore()
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<any>(null)
  const { updateActiveSchoolYear, activeSchoolYear } = useSchoolYear()

  const setupSchoolYears = async (): Promise<any> => {
    const { error, data } = await fetchSchoolYears()
    if (error === null || error === undefined) {
      setSchoolYears(data)
    } else {
      setSchoolYears('error')
    }
  }
  useEffect(() => {
    void setupSchoolYears()
  }, [])

  const handleCreateNewSchoolYear = async (event: any): Promise<any> => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)
    if ((data.name as string).length === 0) {
      addNotification('Le champ "Nom" ne peut pas être vide.', 'error')
      return null
    } else {
      const { error: cError, data: cData } = await createNewSchoolYear(data.name as string)
      if (cError === null || cError === undefined) {
        setSchoolYears([...schoolYears, cData[0]])
        addNotification(`L'année scolaire "${data.name as string}" a été ajoutée avec succès!`, 'success')
        event.target.reset()
      } else {
        addNotification("Une erreur s'est produite lors de l'ajout de l'année scolaire.", 'error')
      }
    }
  }

  const handleUpdateSchoolYearName = async (event: any): Promise<any> => {
    event.preventDefault()
    if (selectedSchoolYear === null) return null

    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)
    if ((data.name as string).length === 0) {
      addNotification('Le champ "Nom" ne peut pas être vide.', 'error')
      return null
    } else {
      const { error: updateError, data: updateData } =
       await updateSchoolYear(selectedSchoolYear.id, { name: data.name as string })
      if (updateError === null || updateError === undefined) {
        if (schoolYears !== 'error' && schoolYears !== 'pending') {
          const arr: any[] = schoolYears.filter((s: any) => {
            return s.id !== selectedSchoolYear.id
          })
          setSelectedSchoolYear(updateData[0])
          arr.push(updateData[0])
          setSchoolYears(arr)
        }
        addNotification("Le nom de l'année a été mis à jour avec succès!", 'success')
      } else {
        addNotification("Une erreur s'est produite lors de la mise à jour de l'année scolaire", 'error')
      }
    }
  }

  const handleMarkSchoolYear = async (event: any): Promise<any> => {
    event.preventDefault()
    if (selectedSchoolYear === null) return null

    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)

    if (data.setactiveyear === 'no') {
      const { error } =
      await disableSchoolYear()
      if (error === null || error === undefined) {
        if (schoolYears !== 'error' && schoolYears !== 'pending') {
          const arr: any[] = schoolYears.filter((s: any) => {
            return s.id !== selectedSchoolYear.id
          })
          arr.push({ ...selectedSchoolYear, is_active: false })
          setSchoolYears(arr)
        }
        addNotification('Configuration enregistrée avec succès!', 'success')
        void updateActiveSchoolYear()
      } else {
        addNotification("Une erreur s'est produite lors de la mise à jour de l'année scolaire", 'error')
      }
    } else if (data.setactiveyear === 'yes') {
      const { error } =
      await disableSchoolYear()
      if (error === null || error === undefined) {
        const { error: updateError } =
        await updateSchoolYear(selectedSchoolYear.id, { is_active: 'yes' })
        if (updateError === null || updateError === undefined) {
          if (schoolYears !== 'error' && schoolYears !== 'pending') {
            await setupSchoolYears()
          }
        }
        addNotification('Configuration enregistrée avec succès!', 'success')
        void updateActiveSchoolYear()
      } else {
        addNotification("Une erreur s'est produite lors de la mise à jour de l'année scolaire", 'error')
      }
    }
  }

  return (
    <SchoolYearScreen schoolyears={schoolYears}
    handleCreateNewSchoolYear={handleCreateNewSchoolYear}
    selectedSchoolYear={selectedSchoolYear}
    setSelectedSchoolYear={setSelectedSchoolYear}
    handleUpdateSchoolYearName={handleUpdateSchoolYearName}
    handleMarkSchoolYear={handleMarkSchoolYear}
    activeSchoolYear={activeSchoolYear}
     />
  )
}
