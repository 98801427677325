interface InputNumberProps {
  placeholder: string | undefined
  name?: string
  value?: string
  min?: number
  max?: number
  onInput?: any
}
export default function InputNumber ({ placeholder, name, value, min, max, onInput }: InputNumberProps): JSX.Element {
  return (
      <>
          <input type='number' placeholder={placeholder} name={name} defaultValue={value}
          min={min} max={max}
          className="outline-none py-2 px-3 rounded-lg text-sm w-full
          border-slate-100 border-1" onInput={onInput}/>
      </>
  )
}
