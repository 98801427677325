import { faArrowRight, faFilter, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import InputText from '../components/InputText/InputText'
import Button from '../components/Button/Button'
import ActionInfo from '../components/ActionInfo/ActionInfo'
import Dropdown from '../components/Dropdown/Dropdown'
import SplashComponent from '../components/Loading/SplashComponent'
import FetchDataError from '../components/Error/FetchDataError'

export default function ClassesScreen ({
  classes,
  lessons,
  handleCreateNewClass,
  creatingStatus,
  updateFilters,
  filterClasses,
  filters
}: any): JSX.Element {
  return (
        <div>
            <div className='grid grid-cols-3 p-4 mb-3 shadow-xl rounded-xl'>
                <ActionInfo info='Ajouter une classe à un département dans votre école'
                icon={faPlus} />
                <div className='col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2'>
                    <form onSubmit={handleCreateNewClass}>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'><InputText placeholder='Nom de la classe' name='name' /></div>
                        <div className='col-span-1'><Dropdown name='lesson_id' placeholder='Cours'
                        options={lessons}
                        /></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'>
                            <Button title='Ajouter' loadingText='Création ...' isLoading={creatingStatus === 'pending'} />
                            {creatingStatus === 'error' &&
                            <p className='py-2 text-xs text-red-500'>Erreur lors de la création du département</p>}
                        </div>
                    </div>
                    </form>
                </div>
            </div>

            <div className='bg-white shadow-xs rounded-lg p-4'>
                <p className='uppercase text-xs text-firstColor'><FontAwesomeIcon icon={faFilter} className='mr-1' />filtrer les classes</p>
                <form onSubmit={(e: any) => { e.preventDefault(); e.target.reset(); updateFilters({ name: '', lesson: null, students: null }) }}>
                <div className='grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4 mt-2'>
                    <div className='col-span-1'>
                        <InputText placeholder='Nom' onInput={(e: any) => {
                          updateFilters((prevFilters: any) => ({ ...prevFilters, name: e.target.value }))
                        }}/>
                    </div>
                    <div className='col-span-1'>
                        <Dropdown options={lessons} placeholder='Cours' name='lesson'
                            onChange={(e: any) => {
                              updateFilters((prevFilters: any) =>
                                ({ ...prevFilters, lesson: Number(e.target.value) }))
                            }} />
                    </div>
                    <div className='col-span-1'>

                    </div>
                </div>
                {(filters.name.length > 0 || filters.classe !== null) && <Button icon={faXmark} title='Réinitialiser' className='float-right my-3' />}
                </form>
            </div>

            <div className='w-full overflow-x-auto mt-6'>
                <table>
                    <thead>
                        <tr>
                        <th>Nom de la classe</th>
                        <th>Cours</th>
                        <th>Date d&apos;de création</th>
                        <th>Afficher</th>
                        </tr>
                    </thead>
                    <tbody>
                        {classes !== 'loading' && classes !== 'error' && filterClasses().map((theClass: any) => (
                            <tr key={theClass.id}>
                            <td>{theClass.name}</td>
                            <td>{theClass.lesson?.name ?? 'N/A'}</td>
                            <td>{(new Date(theClass.created_at)).toLocaleDateString()}</td>
                            <td><Link to={`/classes/${theClass.id}`} state={theClass}><FontAwesomeIcon icon={faArrowRight} className='text-firstColor' /></Link></td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                <SplashComponent cond={classes === 'loading'} />
                <FetchDataError cond={classes === 'error'} />
            </div>

        </div>
  )
}
