import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import InputText from '../components/InputText/InputText'
import Button from '../components/Button/Button'
import ActionInfo from '../components/ActionInfo/ActionInfo'
import SplashComponent from '../components/Loading/SplashComponent'
import FetchDataError from '../components/Error/FetchDataError'

export default function DepartementsScreen ({ departements, handleCreateNewDepartment, newEltState }: any): JSX.Element {
  return (
        <div>
            <div className='grid grid-cols-3 p-4 mb-3 shadow-xl rounded-xl'>
                <ActionInfo info='Ajouter un département à votre école'
                icon={faPlus} />
                <div className='col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2'>
                    <form onSubmit={handleCreateNewDepartment}>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'></div>
                        <div className='col-span-1'><InputText placeholder='Nom' name='name' /></div>
                        <div className='col-span-1'>
                            <Button title='Ajouter' loadingText='Création ...'
                            isLoading={newEltState === 'pending'} />
                            {newEltState === 'error' &&
                            <p className='py-2 text-xs text-red-500'>Erreur lors de la création du département</p>}
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            <div className='w-full overflow-x-auto mt-6'>
                <table>
                    <thead>
                        <tr>
                        <th>Nom</th>
                        <th>Nombre de cours</th>
                        <th>Date d&apos;ajout</th>
                        <th>Afficher</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!['loading', 'error'].includes(departements) && departements.map((dep: any) => (
                            <tr key={dep.id}>
                            <td>{dep.name}</td>
                            <td>{dep.lessons?.length ?? 'N/A'}</td>
                            <td>{(new Date(dep.created_at)).toLocaleDateString()}</td>
                            <td><Link to={`/departments/${dep.id}`}><FontAwesomeIcon icon={faArrowRight} className='text-firstColor' /></Link></td>
                        </tr>
                        ))}

                    </tbody>
                </table>
                <SplashComponent cond={departements === 'loading'} />
                <FetchDataError cond={departements === 'error'} />
            </div>
        </div>
  )
}
