import { useParams } from 'react-router-dom'
import LessonScreen from '../screens/lesson.screen'
import { useEffect, useState } from 'react'
import { fetchDepartments, fetchLesson, removeLesson, updateLesson } from '../services/adminService'
import { useNotificationStore } from '../store/notificationStore'
import useConfirmDialogStore from '../store/confirmDialogStore'

export default function Lesson (): JSX.Element {
  const [lesson, setLesson] = useState<string | any>('pending')
  const [departements, setDepartements] = useState<[]>([])
  const { lessonId } = useParams()
  const { addNotification } = useNotificationStore()
  const [updateStatus, setUpdateStatus] = useState<string | null>(null)
  const { openDialog } = useConfirmDialogStore()

  const setupDepartements = (): void => {
    fetchDepartments().then(res => {
      if (res.error === null) {
        setDepartements(res.data)
      } else {
        addNotification('Impossible de récupérer la liste des départements', 'warning')
      }
    }).catch(() => {
      addNotification('Impossible de récupérer la liste des départements', 'warning')
    })
  }

  useEffect(() => {
    fetchLesson(Number(lessonId)).then(res => {
      if (res.error !== null) {
        setLesson('error')
        addNotification('Page cours non trouvée', 'error')
      } else {
        setLesson(res.data.length > 0 ? res.data[0] : 'notfound')
      }
    }).catch(() => {
      setLesson('error')
    })

    setupDepartements()
  }, [])

  const handleUpdateLesson = (e: any): void => {
    e.preventDefault()
    setUpdateStatus('pending')
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)

    updateLesson(Number(lessonId), data).then(res => {
      setUpdateStatus(null)
      if (res.error === null) {
        setLesson(res.data[0])
        addNotification('Informations mises à jour avec succès!', 'success')
      } else {
        addNotification('Une erreur\'est produite lors de la mise  à jour des informations', 'error')
      }
    }).catch(() => {
      setUpdateStatus(null)
      addNotification('Une erreur\'est produite lors de la mise  à jour des informations', 'error')
    })
  }

  const deleteLesson = async (): Promise<any> => {
    if (lesson !== 'pending' || lesson !== 'error') {
      const { error } = await removeLesson(lesson.id)
      if (error === null || error === undefined) {
        setLesson('notfound')
      } else {
        addNotification("Une erreur s'est produite lors de la suppression du cours", 'error')
      }
    }
  }

  const handleDeleteLesson = (): void => {
    openDialog('Cette opération est irréversible.', () => {
      void deleteLesson()
    })
  }

  return <LessonScreen
   lesson={lesson}
   departments={departements}
   handleUpdateLesson={handleUpdateLesson}
   updateStatus={updateStatus}
   handleDeleteLesson={handleDeleteLesson} />
}
