import { faTrash } from '@fortawesome/free-solid-svg-icons'
import SubTitle from '../components/Title/Subtitle'
import Button from '../components/Button/Button'
import InputText from '../components/InputText/InputText'
import { useEffect } from 'react'
import SplashComponent from '../components/Loading/SplashComponent'
import Page404Error from '../components/Error/Page404Error'
import FormValidationError from '../components/Error/FormValidationError'
import InputNumber from '../components/InputNumber/InputNumber'
import UploadFile from '../components/UploadFile/UploadFile'
import { Link } from 'react-router-dom'

export default function PartitionScreen ({
  partition,
  handleDeletePartition,
  handleUpdatePartition,
  updateStatus
}: any): JSX.Element {
  useEffect(() => {
    console.log('part : ', partition)
  }, [partition])

  if (partition === 'pending') {
    return <SplashComponent />
  } else if (partition === 'error') {
    <Page404Error />
  } else if (partition === 'notfound') {
    return <Page404Error />
  }

  return (
        <div>
            <div></div>
            <div className='w-full mt-6 grid grid-cols-3 gap-2'>
                <div className="col-span-3 lg:col-span-1 xl:col-span-1 2xl:col-span-1">
                    <div className="w-full shadow-lg rounded-lg p-4 py-14">
                        <div className="text-center">
                            <div>
                                <img src={partition?.imgUrl} className='w-full' />
                                <audio controls className='w-full my-2' src={partition.audioUrl}></audio>
                            </div>
                            <p className='font-bold text-xl uppercase tracking-wide'>{partition.name}</p>
                        </div>
                        <div className='mt-6 text-center'>
                            <Button icon={faTrash} title='' onClick={handleDeletePartition} />
                        </div>
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <div className='w-full mb-10'>
                        <SubTitle>Informations de base</SubTitle>
                        <form onSubmit={handleUpdatePartition}>
                            <div className='grid grid-cols-2 gap-2'>
                                <div className='col-span-1'></div>
                                <div className='col-span-1'></div>
                                <div className='col-span-1'></div>
                                <div className='col-span-1'></div>
                                <div className='col-span-1'>
                                <p className='text-sm'>Nom<span className='text-red-500'>*</span></p>
                                <InputText placeholder='Nom' name='name' value={partition.name} />
                                </div>
                                <div className='col-span-1'>
                                <p className='text-sm'>Syllabes</p>
                                <InputNumber min={0} placeholder='Nombre de syllabes' name='syllabes' value={partition.syllabes} />
                                </div>
                                <div className='col-span-1'>
                                    <p className='text-sm'>Image de la partition<span className='text-red-500'>*</span></p>
                                    <UploadFile name='file' label='Charger un fichier' accept='.png,.jpeg,.jpg' />
                                </div>
                                <div className='col-span-1'>
                                <p className='text-sm'>Audio de la partition<span className='text-red-500'>*</span></p>
                                    <UploadFile name='fileAudio' label='Charger un fichier' accept='.mp3' />
                                </div>
                                <div className='col-span-1'></div>
                                <div className='col-span-1'>
                                    <Button title='Mettre  à jour' loadingText='Traitement ...'
                                    isLoading={updateStatus === 'pending'} />
                                    {updateStatus !== null && updateStatus !== 'success' && updateStatus !== 'pending' &&
                                    <FormValidationError text={updateStatus} /> }
                                </div>
                            </div>
                        </form>
                        <div className='mt-14'></div>
                        <SubTitle>Élèves</SubTitle>
                        {partition?.students?.length > 0 && <>
                        <p className='text-sm text-gray-700'>
                            Cette partition est attribuée aux élèves suivants :
                        </p>
                        <ul className='list-disc px-4'>
                            {partition?.students?.map((student: any) => (
                                <li key={student.id}>
                                    <Link to={`/students/${student.id}`}>
                                    {student.firstname + ' ' + student.lastname}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        </>}
                        {partition?.students?.length === 0 &&
                        <p className='text-sm text-gray-500'>Cette partition n&apos;est attribuée a aucun élève pour l&apos;instant</p>}
                    </div>
                </div>
            </div>

        </div>
  )
}
