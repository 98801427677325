import create from 'zustand'

interface ConfirmDialogState {
  isOpen: boolean
  message: string
  onConfirm: (() => void) | null
  openDialog: (message: string, onConfirm: () => void) => void
  closeDialog: () => void
}

const useConfirmDialogStore = create<ConfirmDialogState>((set) => ({
  isOpen: false,
  message: '',
  onConfirm: null,
  openDialog: (message, onConfirm) => { set({ isOpen: true, message, onConfirm }) },
  closeDialog: () => { set({ isOpen: false, message: '', onConfirm: null }) }
}))

export default useConfirmDialogStore
