import { useEffect, useState } from 'react'
import ClassesScreen from '../screens/classes.screen'
import { createNewClass, fetchClasses, fetchLessons } from '../services/adminService'
import { useNotificationStore } from '../store/notificationStore'
import { useSchoolYear } from '../context/SchoolYearContext'

export default function Classes (): JSX.Element {
  const [classes, setClasses] = useState<[] | string>('loading')
  const [lessons, setLessons] = useState<any[]>([])
  const [newEltState, setNewEltState] = useState<null | 'pending' | 'success' | 'error'>(null)
  const [filters, setFilters] = useState<any>({ name: '', lesson: null, students: null })
  const { addNotification } = useNotificationStore()
  const { activeSchoolYear } = useSchoolYear()

  const setUpClasses = async (): Promise<any> => {
    const { error, data } = await fetchClasses()
    if (error === null || error === undefined) {
      console.log('classes : ', data)
      setClasses(data)
    } else {
      setClasses('error')
    }
  }
  const handleCreateNewClass = async (event: any): Promise<any> => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)

    if ((data.name as string).length === 0) {
      addNotification('Le nom de la classe ne peut pas être vide', 'error')
      return
    }

    if (activeSchoolYear === null) {
      addNotification("Impossible de créer une classe car aucune année scolaire n'est marquée comme année courante.", 'error')
      return
    }

    setNewEltState('pending')
    createNewClass({ name: data.name, lessonId: data.lesson_id, schoolyearId: activeSchoolYear.id }).then(async res => {
      if (res.error === null) {
        setNewEltState('success')
        await setUpClasses()
        event.target.reset()
      } else {
        setNewEltState('error')
      }
    }).catch(() => {
      setNewEltState('error')
    })
  }

  useEffect(() => {
    void setUpClasses()
    fetchLessons().then(res => {
      if (res.error === null) {
        const arr = []
        for (let i = 0; i < res.data.length; i++) {
          arr.push({
            label: res.data[i].name,
            value: res.data[i].id
          })
        }
        setLessons(arr)
      }
    }).catch(() => {})
  }, [])

  const filterClasses = (): any => {
    if (filters.name.trim().length === 0 && filters.syllabes === null && filters.students === null) {
      return classes
    }

    if (!Array.isArray(classes)) {
      return classes
    } else {
      return classes.filter((classe: any) => {
        if (filters.name.trim().length > 0 && classe.name.toLowerCase().includes(filters.name.toLowerCase()) === false) {
          return false
        }

        if (filters.lesson !== null && classe.lesson_id !== filters.lesson) {
          return false
        }

        if (filters.students !== null && classe.students.length !== filters.students) {
          return false
        }

        return true
      })
    }
  }

  return (
        <ClassesScreen classes={classes}
        lessons={lessons} handleCreateNewClass={handleCreateNewClass} creatingStatus={newEltState}
        filterClasses={filterClasses} updateFilters={setFilters} filters={filters} />
  )
}
