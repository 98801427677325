/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import PartitionsScreen from '../screens/paritions.screen'
import { createPartition, downloadPartition, fetchPartitionFilePublicURL, fetchPartitions, uploadPartition, uploadPartitionAudio } from '../services/adminService'
import { useNotificationStore } from '../store/notificationStore'
import { downloadFileBrowser } from '../services/browserService'

export default function Partitions (): JSX.Element {
  const [partitions, setPartitions] = useState<string | any[]>('pending')
  const [newEltState, setNewEltState] = useState<string | null | string[]>(null)
  const [filters, setFilters] = useState<any>({ name: '', syllabes: null, students: null })

  const acceptedFileTypes = ['application/pdf']
  const { addNotification } = useNotificationStore()

  useEffect(() => {
    void setupPartitions()
  }, [])

  const setupPartitions = async (): Promise<any> => {
    fetchPartitions().then(async res => {
      if (res.error !== null) {
        setPartitions('error')
      } else {
        const partitions_: any[] = []
        for (let i = 0; i < res.data.length; i++) {
          const { error, data } = await fetchPartitionFilePublicURL(res.data[i].file_path)
          const { error: fetchPartitionAudioFileErr, data: fetchPartitionAudioFileData } = await
          fetchPartitionFilePublicURL(res.data[i].file_audio_path)
          res.data[i].url = data
          if (error === null || error === undefined) {
            res.data[i].url = data?.publicUrl
          }
          res.data[i].audioFileUrl = fetchPartitionAudioFileErr === null || fetchPartitionAudioFileErr === undefined
            ? fetchPartitionAudioFileData.publicUrl
            : ''
        }
        setPartitions(res.data)
      }
    }).catch(() => {
      setPartitions('error')
    })
  }

  const handleCreateNewPartition = (event: any): any => {
    event.preventDefault()
    setNewEltState(null)
    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)
    if ((!(data.file instanceof File) || data.file.size === 0) ||
    (!(data.fileAudio instanceof File) || data.fileAudio.size === 0)) {
      setNewEltState('Veuillez séléctionner l\'image et le fichier audio')
      return null
    } /* else if (!acceptedFileTypes.includes(data.file.type)) {
      setNewEltState('Le type de fichier n\'est pas accepté. Types acceptés : PDF.')
      return null
    } */
    if ((data.name as string).length === 0) {
      setNewEltState('Le nom de la partition est obligatoire')
      return null
    }

    uploadPartition(data).then(async res => {
      if (res.error !== null) {
        setNewEltState('Une erreur s\'est produite lors du chargement du fichier.')
        return null
      } else {
        const { error: errUploadAudio, data: dataUploadAudio } = await uploadPartitionAudio({ file: data.fileAudio })

        if (errUploadAudio === null || errUploadAudio === undefined) {
          const { error: errCreatePartition, data: dataCreatePartition } =
          await createPartition({
            name: data.name,
            syllabes: data.syllabes as unknown as number,
            filePath: res.data.path,
            filePathAudio: dataUploadAudio.path
          })
          if (errCreatePartition === null || errCreatePartition === undefined) {
            event.target.reset()
            addNotification('Partition créée avec succès!', 'success')
            setPartitions([...partitions, dataCreatePartition[0]])
          } else {
            setNewEltState('Une erreur s\'est produite lors de la création de la partition')
          }
        } else {
          setNewEltState('Le fichier audio n\' pas été chargé sur le serveur.')
          addNotification('Le fichier audio n\' pas été chargé sur le serveur.', 'error')
        }
      }
    }).catch(() => {
      setNewEltState('Une erreur s\'est produite lors de la création de la partition')
    })
  }

  const handleDownloadPartitionFile = (filePath: string): void => {
    downloadPartition({ filePath }).then(res => {
      if (res.error === null) {
        addNotification('Le téléchargement du fichier a commencé', 'infos')
        downloadFileBrowser(res.data, filePath)
      }
    }).catch(() => {
    })
  }

  const filterPartitions = (): any => {
    if (filters.name.trim().length === 0 && filters.syllabes === null && filters.students === null) {
      return partitions
    }

    if (!Array.isArray(partitions)) {
      return partitions
    } else {
      return partitions.filter((partition: any) => {
        if (filters.name.trim().length > 0 && partition.name.toLowerCase().includes(filters.name.toLowerCase()) === false) {
          return false
        }

        if (filters.syllabes !== null && partition.syllabes !== filters.syllabes) {
          return false
        }

        if (filters.students !== null && partition.students.length !== filters.students) {
          return false
        }

        return true
      })
    }
  }

  useEffect(() => {
  }, [filters])

  return (
    <PartitionsScreen
      partitions={partitions}
      handleCreateNewPartition={handleCreateNewPartition}
      newEltState={newEltState}
      handleDownloadPartitionFile={handleDownloadPartitionFile}
      updateFilters={setFilters}
      filterPartitions={filterPartitions}
    />
  )
}
