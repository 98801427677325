import { useEffect, useState } from 'react'
import UsersScreen from '../screens/users.screen'
import { createProfile, createUser, fetchUserProfiles } from '../services/adminService'
import { useNotificationStore } from '../store/notificationStore'

export default function Users (): JSX.Element {
  const [users, setUsers] = useState <any[] | string>('loading')
  const { addNotification } = useNotificationStore()

  const handleSubmitCreateUser = async (e: any): Promise<any> => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)

    if ((data.email as string).length === 0) {
      addNotification('Veuillez saisir une adresse e-mail valide.', 'error')
      return
    }
    if ((data.password as string).length < 8) {
      addNotification('Le mot de passe doit avoir au moins 8 caractères', 'error')
      return
    }

    const { error: createUserErr, data: createUserData } =
    await createUser({
      email: data.email,
      password: data.password
    })
    console.log('created user : ', createUserErr, createUserData)
    if (createUserErr === undefined || createUserData === null) {
      const { error: createProfileErr, data: createProfileData } =
      await createProfile(createUserData.user.user.id, data.firstname as string, data.lastname as string, data.role as string)
      if (createProfileErr === undefined) {
        addNotification('Le profile n\'a pas été créé.', 'error')
      } else {
        addNotification('utilisateur créé avec succès!', 'success')
        const createdNewUserFullObject = { ...createProfileData[0], user: createUserData.user.user }
        setUsers([...users, createdNewUserFullObject])
        e.target.reset()
      }
    }
  }

  useEffect(() => {
    fetchUserProfiles().then(res => {
      if (res.error === null) {
        setUsers(res.data)
      } else {
        setUsers('error')
      }
    }).catch(() => { setUsers('error') })
  }, [])

  return (
        <UsersScreen users={users}
        handleSubmitCreateUser={handleSubmitCreateUser}
        />
  )
}
