import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { fetchDepartment, removeDepartement, updateDepartment } from '../services/adminService'
import { useNotificationStore } from '../store/notificationStore'
import DepartementScreen from '../screens/departement.screen'
import useConfirmDialogStore from '../store/confirmDialogStore'

export default function Departement (): JSX.Element {
  const [departement, setDepartement] = useState<string | any>('pending')
  const { departementId } = useParams()
  const { addNotification } = useNotificationStore()
  const [updateStatus, setUpdateStatus] = useState<string | null>(null)
  const { openDialog } = useConfirmDialogStore()

  useEffect(() => {
    fetchDepartment(Number(departementId)).then(res => {
      if (res.error !== null) {
        setDepartement('error')
        addNotification('Page département non trouvée', 'error')
      } else {
        setDepartement(res.data.length > 0 ? res.data[0] : 'notfound')
      }
    }).catch(() => { setDepartement('error') })
  }, [])

  const handleupdateDepartment = (e: any): void => {
    e.preventDefault()
    setUpdateStatus('pending')
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)

    updateDepartment(Number(departementId), data).then(res => {
      setUpdateStatus(null)
      if (res.error === null) {
        setDepartement(res.data[0])
        addNotification('Informations mises à jour avec succès!', 'success')
      } else {
        addNotification('Une erreur\'est produite lors de la mise  à jour des informations', 'error')
      }
    }).catch(() => {
      setUpdateStatus(null)
      addNotification('Une erreur\'est produite lors de la mise  à jour des informations', 'error')
    })
  }

  const deleteDepartement = async (): Promise<any> => {
    const { error } = await removeDepartement(departement.id)
    if (error === null || error === undefined) {
      setDepartement('notfound')
    } else {
      addNotification("Une erreur s'est produite lors de la suppression du département", 'error')
    }
  }

  const handleDeleteDepartement = (): void => {
    if (departement !== 'error' && departement !== 'pending') {
      openDialog('Cette opération est irréversible', () => {
        void deleteDepartement()
      })
    }
  }

  return <DepartementScreen
   departement={departement}
   handleupdateDepartment={handleupdateDepartment}
   updateStatus={updateStatus}
   handleDeleteDepartement={handleDeleteDepartement} />
}
