/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useParams } from 'react-router-dom'
import { jsPDF } from 'jspdf'
import html2pdf from 'html2pdf.js'
import ClasseScreen from '../screens/classe.screen'
import { useEffect, useState } from 'react'
import { downloadPartition, fetchClasse, fetchLessons, removeClasse, updateClasse } from '../services/adminService'
import { useNotificationStore } from '../store/notificationStore'
import { downloadFileBrowser } from '../services/browserService'
import { useUser } from '../context/UserContext'
import useConfirmDialogStore from '../store/confirmDialogStore'

export default function Classe (): JSX.Element {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { classeId } = useParams()
  const [classe, setClasse] = useState<any>('pending')
  const [lessons, setLessons] = useState<any[]>([])
  const { addNotification } = useNotificationStore()
  const user = useUser()
  const { openDialog } = useConfirmDialogStore()

  useEffect(() => {
    fetchLessons().then(res => {
      if (res.error === null) {
        const arr = []
        for (let i = 0; i < res.data.length; i++) {
          arr.push({
            label: res.data[i].name,
            value: res.data[i].id
          })
        }
        setLessons(arr)
      }
    }).catch(() => {})

    fetchClasse(Number(classeId)).then(res => {
      console.log('res classe : ', res)
      if (res.error === null) {
        setClasse(res.data.length > 0 ? res.data[0] : 'notfound')
      } else {
        setClasse('error')
        addNotification('Page classe introuvable!', 'error')
      }
    }).catch(() => {
      setClasse('error')
      addNotification('Page classe introuvable!', 'error')
    })
  }, [])

  const handleDownloadStudentPartition = (partition: any): void => {
    if (partition === null) {
      addNotification('Aucune partition n\'est associée à cet élève!', 'error')
    } else {
      handleDownloadPartitionFile(partition.file_path)
    }
  }

  const handleDownloadPartitionFile = (filePath: string): void => {
    downloadPartition({ filePath }).then(res => {
      if (res.error === null) {
        addNotification('Le téléchargement du fichier a commencé', 'infos')
        downloadFileBrowser(res.data, filePath)
      }
    }).catch(() => {
      addNotification('Une erreur s\'est produite lors du téléchargement du fichier', 'error')
    })
  }

  const downloadPartitionFileAsData = async (filePath: string): Promise<any> => {
    let err: boolean = true
    let data: any = null

    await downloadPartition({ filePath }).then(res => {
      if (res.error === null) {
        err = false
        data = res.data
      }
    }).catch(() => {})

    return !err ? data : 'err'
  }

  const handleGetClassePartition = async (download: boolean = true): Promise<any> => {
    if (download) {
      addNotification('Le téléchargement a commencé, merci de patienter', 'infos')
    }

    const MAX_PAGE_HEIGHT = 297
    const MAX_PAGE_WIDTH = 210
    const pxTomm = (px: number, dpi = 96): any => { return px / (dpi * (1 / 25.4)) }
    const mmToPx = (mm: number, dpi = 96): any => mm * (dpi * (1 / 25.4))
    const calculateImageHeight = (originalWidth: any, originalHeight: any, finalWidth: any): any => {
      const ratio = finalWidth / originalWidth

      const finalHeight = originalHeight * ratio

      return finalHeight
    }

    try {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF()

      const paragraphHeight = pxTomm(60)
      const headerHeight = pxTomm(100)
      const themeFirstColor = '#a83c65'
      const htmlHeader = `
      <div style='width:100%;height:${headerHeight}mm;background-color:${themeFirstColor};display:flex;align-items:center;justify-content:center;'>
      <p style='font-family:serif;color:#ffffff;'><span style='text-transform:uppercase;'>prénoms</span> école Koenig ${(new Date()).getFullYear()}</p>
      </div>
      `
      let html = '<div>'
      html += download ? htmlHeader : ''
      let i = 0

      await Promise.all(
        classe.subscriptions?.map(async (subscription: any) => {
          const student = subscription?.student
          try {
            if (student.partition === null) return

            const data = await downloadPartitionFileAsData(student.partition.file_path)

            if (data === 'err') return

            const reader = new FileReader()
            reader.readAsDataURL(data)

            await new Promise<void>((resolve) => {
              reader.onloadend = async () => {
                const base64Image = (reader.result as string).split(',')[1]

                const tempImage = new Image()
                tempImage.src = 'data:image/png;base64,' + base64Image

                // eslint-disable-next-line promise/param-names
                await new Promise<void>((resolveImage) => {
                  tempImage.onload = () => {
                    const imageHeight = pxTomm(calculateImageHeight(tempImage.width, tempImage.height, mmToPx(MAX_PAGE_WIDTH * 0.80)))

                    const anticipatedHeight = i + imageHeight + paragraphHeight
                    const remainingSpace = MAX_PAGE_HEIGHT - i

                    if ((i + paragraphHeight + imageHeight) >= MAX_PAGE_HEIGHT) {
                      html += '<div style=\'background-color:white;page-break-after:always;\'></div>'
                      html += download ? htmlHeader : ''
                      i = 0
                    }

                    html += "<div style='width:80%;margin-left:auto;margin-right:auto;'>"
                    html += `<div style="height:${paragraphHeight}mm;font-weight:bold;font-family:'courrier',serif;padding-left:10px;">
                    ${student.firstname} ${student.lastname}
                    </div>`
                    html += `<img src='data:image/png;base64,${base64Image}' style='width:100%;' />`
                    html += '</div>'

                    i += imageHeight + paragraphHeight + (i === 0 ? headerHeight : 0)

                    resolveImage()
                  }
                })

                resolve()
              }
            })
          } catch (err) {
            console.error('Error processing student partition:', err)
            addNotification('Une erreur s\'est produite lors de la préparation de la partition individuelle', 'error')
          }
        })
      )

      html += '</div>'

      if (!download) {
        return { html }
      }

      const options = {
        margin: 0,
        filename: 'mon_document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }

      const worker = html2pdf()
      worker.from(html).set(options).save()
    } catch (error) {
      console.error('Error creating PDF document:', error)
    }
  }

  const handleUpdateClasse = (e: any): void => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)
    if ((data.name as string).length === 0) {
      addNotification('Le nom de la classe ne peut pas être vide', 'error')
      return
    }
    updateClasse(classe.id, data).then(res => {
      if (res.error === null) {
        e.target.reset()
        addNotification('Informations mises à jour avec succès!', 'success')
        setClasse(res.data[0])
      } else {
        addNotification('Une erreur s\'est produite lors de la mise à jour d ela classe.', 'error')
      }
    }).catch(() => {
      addNotification('Une erreur s\'est produite lors de la mise à jour d ela classe.', 'error')
    })
  }

  const deleteClasse = async (): Promise<any> => {
    if (classe !== 'pending' || classe !== 'error') {
      const { error } = await removeClasse(classe.id)
      if (error === null || error === undefined) {
        setClasse('notfound')
      } else {
        addNotification("Une erreur s'est produite lors de la suppression de la classe", 'error')
      }
    }
  }

  const handleDeleteClasse = (): void => {
    openDialog('Cette opération est irréversible.', () => {
      void deleteClasse()
    })
  }

  return <ClasseScreen
  classe={classe}
  lessons={lessons}
  handleDownloadStudentPartition={handleDownloadStudentPartition}
  handleGetClassePartition={handleGetClassePartition}
  handleUpdateClasse={handleUpdateClasse}
  user={user}
  handleDeleteClasse={handleDeleteClasse}
   />
}
