import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import InputText from '../components/InputText/InputText'
import InputEmail from '../components/InputEmail/InputEmail'
import InputPassword from '../components/InputPassword/InputPassword'
import Button from '../components/Button/Button'
import Dropdown from '../components/Dropdown/Dropdown'
import ActionInfo from '../components/ActionInfo/ActionInfo'
import SplashComponent from '../components/Loading/SplashComponent'
import FetchDataError from '../components/Error/FetchDataError'

export default function UsersScreen (props: any): JSX.Element {
  return (
        <div>
            <div className='grid grid-cols-3 p-4 mb-3 shadow-xl rounded-xl'>
                <ActionInfo info='Vous pouvez ajouter un utilisateur que ce soit un Administrateur ou un Enseignant'
                icon={faPlus} />
                <div className='col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2'>
                    <form onSubmit={props.handleSubmitCreateUser}>
                        <div className='grid grid-cols-2 gap-2'>
                            <div className='col-span-1'><InputText placeholder='Prénom' name='firstname' /></div>
                            <div className='col-span-1'><InputText placeholder='Nom' name='lastname' /></div>
                            <div className='col-span-1'><InputEmail placeholder='E-mail' disableAutocomplete name='email' /></div>
                            <div className='col-span-1'><InputPassword placeholder='Mot de passe' disableAutoComplete name='password' /></div>
                            <div className='col-span-1'><Dropdown options={[
                              { value: 'admin', label: 'Administrateur' },
                              { value: 'teacher', label: 'Enseignant' }
                            ]} placeholder='Rôle' name='role' /></div>
                            <div className='col-span-1'><Button title='Créer' /></div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='w-full overflow-x-auto mt-6'>
                <table>
                    <thead>
                        <tr>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>E-mail</th>
                        <th>Rôle</th>
                        <th>Date d&apos;ajout</th>
                        <th>Afficher</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!['loading', 'error'].includes(props.users) && props.users?.map((user: any) => (
                            <tr key={user.user.id}>
                                <td>{user.firstname}</td>
                                <td>{user.lastname}</td>
                                <td>{user.user.email}</td>
                                <td>{user.role === 'admin' ? 'Administrateur' : 'Enseignant'}</td>
                                <td>{(new Date(user.user.created_at)).toLocaleDateString()}</td>
                                <td><Link to={`/users/${user.id}`}
                                state={user}
                                ><FontAwesomeIcon icon={faArrowRight} className='text-firstColor' /></Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <SplashComponent cond={props.users === 'loading'} />
                <FetchDataError cond={props.users === 'error'} />
            </div>

        </div>
  )
}
