interface Props {
  loadingText?: string
  cond?: boolean
}

export default function SplashComponent ({ loadingText = 'Chargement', cond = true }: Props): JSX.Element {
  if (cond) {
    return (
        <div className="py-8">
          <div className={'w-full h-full flex items-center justify-center  block'}>
              <div className="w-6 h-6 border-t-2 border-b-2 border-firstColor rounded-full animate-spin mr-2" />
          </div>
          <p className="text-center pt-2 text-xs text-gray-600">{loadingText}</p>
        </div>
    )
  }
  return <></>
}
