import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import HappyMusic from '../assets/images/happy_music.svg'
import Button from '../components/Button/Button'
import cubes from '../assets/images/cubes.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SplashComponent from '../components/Loading/SplashComponent'
import FetchDataError from '../components/Error/FetchDataError'

export default function DashboardTeacherScreen ({
  user,
  teacherClassesAccess
}: any): JSX.Element {
  return (
    <div className="container mt-8">
      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-8'>
        <div className='col-span-1 lg:col-span-2 xl:col-span-2 2xl:col-span-2'>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-8 bg-white shadow-lg rounded-xl p-8">
                <div className="col-span-1 text-gray-700">
                <h2 className="text-2xl font-bold mb-4">Bienvenue, {user?.firstname} !</h2>
                <p className="text-md mb-6 text-justify">
                    Cet espace est le vôtre. Explorez vos classes, visualisez et téléchargez les partitions musicales individuelles, et créez des playlists pour vos élèves.
                </p>
                <div className="flex items-center">
                    <a href="#mes-classes">
                    <Button title="Mes classes" icon={faArrowDown} className="uppercase" />
                    </a>
                </div>
                </div>
                <div className="col-span-1 w-3/5 mx-auto hidden lg:block xl:block 2xl:block">
                <img src={HappyMusic} className="w-full" alt="Happy Music" />
                </div>
            </div>
        </div>
        <div className='col-span-1'>
            <div className='shadow-lg py-4 rounded-xl bg-gray-100' style={{ backgroundImage: `url(${cubes})` }}>
                <div className='text-center'>
                    <p className='text-6xl font-bold'><span className='shadow-2xl text-black inline-block w-2/5 py-4'>{teacherClassesAccess.length}</span></p>
                    <p className='text-lg text-black font-semibold uppercase mt-1'>Classes</p>
                </div>
                <div className='text-center mt-8'>
                    <p className='text-6xl font-bold'><span className='shadow-2xl text-black inline-block w-2/5 py-4'>{ teacherClassesAccess !== 'pending' &&
                    teacherClassesAccess !== 'error'
                      ? teacherClassesAccess?.reduce((acc: number, val: any) => acc + (val?.classe?.students?.length ?? 0)
                        , 0)
                      : ''
                    }</span></p>

                    <p className='text-lg text-black font-semibold uppercase mt-1'>élèves</p>
                </div>
            </div>
        </div>
      </div>

      <div className='mt-8 w-full overflow-x-auto ' id="mes-classes">
        <table>
            <thead>
                <tr>
                    <th>Année scolaire</th>
                    <th>Nom de la classe</th>
                    <th>Cours</th>
                    <th>Département</th>
                    <th>Nombre d&apos;élèves</th>
                    <th>Consulter</th>
                </tr>
            </thead>
            <tbody>
                {
                teacherClassesAccess !== 'pending' &&
                teacherClassesAccess !== 'error' &&
                teacherClassesAccess.map((classeAccess: any) => (
                    <tr key={classeAccess.id}>
                    <td>{classeAccess?.classe?.schoolyear?.name ?? 'N/A'}</td>
                    <td>{classeAccess.classe?.name ?? 'N/A'}</td>
                    <td>{classeAccess.classe?.lesson?.name ?? 'N/A'}</td>
                    <td>{classeAccess.classe?.lesson?.departement?.name ?? 'N/A'}</td>
                    <td>{classeAccess.classe?.students?.length ?? 'N/A'}</td>
                    <td><Link to={`/classes/${classeAccess.classe?.id}`}>
                        <FontAwesomeIcon
                        icon={faArrowRight}
                         className='text-firstColor' />
                         </Link>
                    </td>
                </tr>
                ))
                }
            </tbody>
        </table>
        <SplashComponent cond={teacherClassesAccess === 'pending'} />
        <FetchDataError cond={teacherClassesAccess === 'error'} />
      </div>

    </div>
  )
}
