import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../Button/Button'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import useConfirmDialogStore from '../../store/confirmDialogStore'

export default function ConfirmDialog (): JSX.Element {
  const { isOpen, message, onConfirm, closeDialog } = useConfirmDialogStore()

  const handleConfirm = (): void => {
    onConfirm?.()
    closeDialog()
  }
  if (!isOpen) {
    return <></>
  }

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-8 rounded shadow-md z-10">
        <p className="mb-4 text-sm"><FontAwesomeIcon className='mr-1' icon={faInfoCircle} />
        {message} Veuillez confirmer ou annuler.</p>
        <div className="flex justify-end">
          <Button onClick={closeDialog} title="Annuler" className="bg-secondColor text-gray-900 px-4 mr-2" />
          <Button onClick={handleConfirm} title="Confirmer" />
        </div>
      </div>
    </div>
  )
}
