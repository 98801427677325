import FetchDataError from '../components/Error/FetchDataError'
import Page403Error from '../components/Error/Page403Error'
import SplashScreen from '../components/Loading/SplashScreen'
import { useUser } from '../context/UserContext'

export default function AdminRoleMiddleware ({ children }: any): JSX.Element {
  const user = useUser()

  if (user === 'pending') {
    return <SplashScreen />
  } else if (user === 'error') {
    return <FetchDataError text="Nous n'avons pas pu récupérer vos données, veuillez réessayer." />
  }

  if (user.role === 'admin') {
    return (
      children
    )
  } else {
    return (
      <Page403Error />
    )
  }
}
