import { useEffect, useState } from 'react'
import Page404Error from '../components/Error/Page404Error'
import SplashComponent from '../components/Loading/SplashComponent'
import { useUser } from '../context/UserContext'
import DashboardScreen from '../screens/dashboard.screen'
import DashboardTeacherScreen from '../screens/dashboard.teacher.screen'
import { fetchTeacherClassesAccess } from '../services/teacherService'
import { fetchClasses, fetchDepartments, fetchLessons, fetchPartitions, fetchStudents, fetchUserProfiles } from '../services/adminService'

interface MetricsInterface {
  users: number
  departements: number
  lessons: number
  classes: number
  students: number
  partitions: number
}

export default function Dashboard (): JSX.Element {
  const [teacherClassesAccess, setTeacherClassesAccess] = useState<any[] | string>('pending')
  const [metrics, setMetrics] = useState<MetricsInterface | 'pending' | 'error'>('pending')
  const user = useUser()

  const setupTeacherClassesAccess = async (): Promise<any> => {
    const { error, data } = await fetchTeacherClassesAccess(user.user.id)
    if (error === null || error === undefined) {
      setTeacherClassesAccess(data)
    } else {
      setTeacherClassesAccess('error')
    }
  }
  const setupMetrics = async (): Promise<any> => {
    const [users_, departements_, lessons_, classes_, students_, partitions_] =
    await Promise.all([
      fetchUserProfiles(),
      fetchDepartments(),
      fetchLessons(),
      fetchClasses(),
      fetchStudents(),
      fetchPartitions()
    ])
    if (users_.error === null &&
      departements_.error === null &&
      lessons_.error === null &&
      classes_.error === null &&
      students_.error === null &&
      partitions_.error === null) {
      setMetrics({
        users: users_.data.length,
        departements: departements_.data.length,
        lessons: lessons_.data.length,
        classes: classes_.data.length,
        students: students_.data.length,
        partitions: partitions_.data.length
      })
    } else {
      setMetrics('error')
    }
  }
  useEffect(() => {
    if (user !== 'pending' && user !== 'error') {
      if (user.role === 'teacher') {
        void setupTeacherClassesAccess()
      } else if (user.role === 'admin') {
        void setupMetrics()
      }
    }
  }, [user])

  if (user === 'pending') {
    return <SplashComponent />
  }

  if (user.role === 'admin') {
    return <DashboardScreen user={user} metrics={metrics} />
  } else if (user.role === 'teacher') {
    return <DashboardTeacherScreen user={user} teacherClassesAccess={teacherClassesAccess} />
  } else {
    return <Page404Error />
  }
}
