import { faArrowRight, faFileDownload, faFilter, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import ActionInfo from '../components/ActionInfo/ActionInfo'
import Button from '../components/Button/Button'
import Dropdown from '../components/Dropdown/Dropdown'
import InputText from '../components/InputText/InputText'
import SplashComponent from '../components/Loading/SplashComponent'
import FetchDataError from '../components/Error/FetchDataError'

export default function StudentsScreen ({
  students,
  classes,
  handleCreateNewStudent,
  creatingStatus,
  handleDownloadPartitionFile,
  updateFilters,
  filterStudents,
  filters
}: any): JSX.Element {
  return (
        <div>
            <div className='grid grid-cols-3 p-4 mb-3 shadow-xl rounded-xl'>
                <ActionInfo info='Créer un nouveau étudiant avec possibilité de l&apos;affecter à une classe'
                icon={faPlus} />
                <div className='col-span-3 lg:col-span-2 xl:col-span-2 2xl:col-span-2'>
                    <form onSubmit={handleCreateNewStudent}>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='col-span-1'><InputText placeholder='Prénom' name='firstname' /></div>
                        <div className='col-span-1'><InputText placeholder='Nom' name='lastname' /></div>
                        <div className='col-span-1'>
                            <Dropdown name='classe_id' nullable options={
                                classes
                                  .map((classe: any) => (
                                    {
                                      label: `${classe?.name} (${classe?.lesson?.departement?.name ?? 'N/A'})`,
                                      value: classe.id
                                    }
                                  ))
                            } placeholder='Choisir une classe' />
                        </div>
                        <div className='col-span-1'><Button title='Créer' />
                        {creatingStatus === 'error' &&
                            <p className='py-2 text-xs text-red-500'>Erreur lors de la création de l&apos;élève.</p>}
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            <div className='bg-white shadow-xs rounded-lg p-4'>
                <p className='uppercase text-xs text-firstColor'><FontAwesomeIcon icon={faFilter} className='mr-1' />filtrer la liste des élèves
                </p>
                <form onSubmit={(e: any) => { e.preventDefault(); e.target.reset(); updateFilters({ name: '', classe: null }) }}>
                <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4 mt-2'>
                    <div className='col-span-1'>
                        <p className='text-xs mb-1'>Nom et/ou prénom</p>
                        <InputText placeholder='Nom et/ou prénom' name='name' onInput={(e: any) => {
                          updateFilters((prevFilters: any) => ({ ...prevFilters, name: e.target.value }))
                        }}/>
                    </div>
                    <div className='col-span-1'>
                        <p className='text-xs mb-1'>Classe</p>
                        <Dropdown options={classes.map((classe: any) => ({
                          label: `${classe.name} (${classe.lesson?.name})`,
                          value: classe.id
                        }))} placeholder='Classe' name='classe'
                        onChange={(e: any) => {
                          updateFilters((prevFilters: any) =>
                            ({ ...prevFilters, classe: Number(e.target.value) }))
                        }} />
                    </div>
                </div>
                {(filters.name.length > 0 || filters.classe !== null) && <Button icon={faXmark} title='Réinitialiser' className='float-right my-3' />}
                </form>
            </div>
            <div className='w-full overflow-x-auto mt-6'>
                <table>
                    <thead>
                        <tr>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Prénom Musical</th>
                        <th>Voir la fiche</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!['loading', 'error'].includes(students) && filterStudents().map((student: any) => (
                            <tr key={student.id}>
                            <td>{student.firstname}</td>
                            <td>{student.lastname}</td>
                            <td>
                                {student.partition !== null &&
                                <FontAwesomeIcon icon={faFileDownload} className='mr-1 text-firstColor cursor-pointer'
                                onClick={(event) => handleDownloadPartitionFile(student.partition?.file_path)} />}
                                {student.partition?.name ?? 'N/A'}</td>
                            <td><Link to={`/students/${student.id}`}
                            state={student}><FontAwesomeIcon icon={faArrowRight} className='text-firstColor' /></Link></td>
                        </tr>
                        ))}

                    </tbody>
                </table>
                <SplashComponent cond={students === 'loading'} />
                <FetchDataError cond={students === 'error'} />
            </div>

        </div>
  )
}
