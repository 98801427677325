import UserScreen from '../screens/user.screen'
import { useLocation } from 'react-router-dom'
import { createNewTeacherClassesAccess, fetchClasses, fetchDepartments, fetchLessons, fetchTeacherClassesAccess, removeTeacherClassesAccess, updateUserProfile } from '../services/adminService'
import { useEffect, useState } from 'react'
import { useNotificationStore } from '../store/notificationStore'
import useConfirmDialogStore from '../store/confirmDialogStore'

export default function User (): JSX.Element {
  const location = useLocation()
  const [userProfile, setUserProfile] = useState(location.state)
  const [classes, setClasses] = useState<any[] | 'pending' | 'error'>('pending')
  const [departements, setDepartements] = useState<any[] | 'pending' | 'error'>('pending')
  const [lessons, setLessons] = useState<any[] | 'pending' | 'error'>('pending')
  const [teacherClassesAccess, setTeacherClassesAccess] = useState<any[] | 'pending' | 'error'>('pending')
  const { addNotification } = useNotificationStore()
  const { openDialog } = useConfirmDialogStore()

  useEffect(() => {
    void setupClasses()
    void setupDepartements()
    void setupLessons()
    void setupTeacherClassesAccess()
  }, [])

  const setupClasses = async (): Promise<any> => {
    const { error, data } = await fetchClasses()
    if (error === null || error === undefined) {
      setClasses(data)
    } else {
      setClasses('error')
      addNotification(`Nous n'avons pas pu récupérer 
      la liste des classes, l'ajout de nouvelles autorisation sera impossible!`, 'error')
    }
  }

  const setupDepartements = async (): Promise<any> => {
    const { error, data } = await fetchDepartments()
    if (error === null || error === undefined) {
      setDepartements(data)
    } else {
      setDepartements('error')
      addNotification(`Nous n'avons pas pu récupérer 
      la liste des départements, l'ajout de nouvelles autorisation sera impossible!`, 'error')
    }
  }

  const setupLessons = async (): Promise<any> => {
    const { error, data } = await fetchLessons()
    if (error === null || error === undefined) {
      setLessons(data)
    } else {
      setLessons('error')
      addNotification(`Nous n'avons pas pu récupérer 
      la liste des cours, l'ajout de nouvelles autorisation sera impossible!`, 'error')
    }
  }

  const setupTeacherClassesAccess = async (): Promise<any> => {
    const { error, data } = await fetchTeacherClassesAccess(userProfile?.user?.id)
    if (error === null || error === undefined) {
      setTeacherClassesAccess(data)
    } else {
      setTeacherClassesAccess('error')
      addNotification(`Nous n'avons pas pu récupérer 
      la liste des permissions déjà ajoutées!`, 'error')
    }
  }

  const handleUpdateUserProfile = async (event: any): Promise<any> => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const serializedData = Object.fromEntries(formData)
    const { updated } = await updateUserProfile(serializedData)
    if (updated === true) {
      const updatedUserProfile = { ...userProfile, ...serializedData }
      setUserProfile(updatedUserProfile)
      event.target.reset()
      addNotification('Profile mis à jour avec succès!', 'success')
    } else {
      addNotification('Une erreur s\'est produite, veuillez réessayer...', 'error')
    }
  }

  const handleAddNewAccessPermission = async (event: any): Promise<any> => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)

    const { error, data: resData } =
    await createNewTeacherClassesAccess(userProfile?.user?.id, (data.classeId) as unknown as number)
    event.target.reset()

    if (error === null || error === undefined) {
      setTeacherClassesAccess([...teacherClassesAccess, resData[0]])
      addNotification(`Désormais, ${userProfile.firstname + ' ' + userProfile.lastname}
      a accès à la classe ${resData[0]?.classe?.name ?? 'N/A'}`, 'success')
    } else {
      addNotification('Une erreur s\'est produite lors de l\'ajout de la nouvelle permission', 'error')
    }
  }

  const deletePermission = async (id: number): Promise<any> => {
    const { error } = await removeTeacherClassesAccess(id)
    if (error === null || error === undefined) {
      addNotification('Permission retirée avec succès!', 'success')
      if (Array.isArray(teacherClassesAccess)) {
        setTeacherClassesAccess(teacherClassesAccess.filter((elt: any) => {
          return elt.id !== id
        }))
      }
    } else {
      addNotification("Une erreur s'est produite lors de la suppression de la permission", 'error')
    }
  }

  const handleDeletePermission = (id: number): void => {
    openDialog('Voulez-vous vraiment supprimer cette permission ?', () => {
      void deletePermission(id)
    })
  }

  return (
        <UserScreen userProfile={userProfile}
        handleUpdateUserProfile={handleUpdateUserProfile}
        classes={classes}
        departements={departements}
        lessons={lessons}
        teacherClassesAccess={teacherClassesAccess}
        handleAddNewAccessPermission={handleAddNewAccessPermission}
        handleDeletePermission={handleDeletePermission}
        />
  )
}
