import { useEffect, useState } from 'react'
import { createNewLesson, fetchDepartments, fetchLessons } from '../services/adminService'
import LessonsScreen from '../screens/Lessons'
import { useNotificationStore } from '../store/notificationStore'

export default function Lessons (): JSX.Element {
  const [lessons, setLessons] = useState<any[] | string>('loading')
  const [newEltState, setNewEltState] = useState<null | 'pending' | 'success' | 'error'>(null)
  const [departements, setDepartements] = useState<[]>([])
  const [filters, setFilters] = useState<any>({ name: '', departement: null })
  const { addNotification } = useNotificationStore()

  const setupStudents = (): void => {
    fetchLessons().then(res => {
      if (res.error === null) {
        setLessons(res.data)
      }
    }).catch(() => {
      setLessons('error')
    })
  }

  const setupDepartements = (): void => {
    fetchDepartments().then(res => {
      if (res.error === null) {
        setDepartements(res.data)
      } else {
        addNotification('Impossible de récupérer la liste des départements', 'warning')
      }
    }).catch(() => {
      addNotification('Impossible de récupérer la liste des départements', 'warning')
    })
  }

  const handleCreateLesson = (event: any): void => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)

    if ((data.name as string).length === 0) {
      addNotification('Le nom du cours ne peut pas être vide', 'error')
      return
    }

    setNewEltState('pending')

    createNewLesson(data).then(res => {
      if (res.error === null) {
        setNewEltState('success')
        setLessons([...lessons, res.data[0]])
        addNotification('Le cours a été créé avec succès!', 'success')
        event.target.reset()
      } else setNewEltState('error')
    }).catch(() => {
      setNewEltState('error')
    })
  }

  useEffect(() => {
    setupStudents()
    setupDepartements()
  }, [])

  const filterLessons = (): any => {
    if (filters.name.trim().length === 0 && filters.syllabes === null && filters.students === null) {
      return lessons
    }

    if (!Array.isArray(lessons)) {
      return lessons
    } else {
      return lessons.filter((lesson: any) => {
        if (filters.name.trim().length > 0 && lesson.name.toLowerCase().includes(filters.name.toLowerCase()) === false) {
          return false
        }

        if (filters.departement !== null && lesson.departement_id !== filters.departement) {
          return false
        }

        return true
      })
    }
  }

  return (
        <LessonsScreen lessons={lessons}
        handleCreateLesson={handleCreateLesson} creatingStatus={newEltState}
        filterLessons={filterLessons} filters={filters} updateFilters={setFilters} departements={departements} />
  )
}
